import React, { useState, useEffect } from "react";
import { Box, Typography, InputBase, Button } from "@mui/material";
import "./Signin.scss";
import signin from "../images/signin.png";
import { country_list } from "./countryList";
import ReactFlagsSelect from "react-flags-select";
import { generatePopup } from "../utils/popup";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  validateOtp,
  validateValues,
} from "../validation/signin";
import { phonecheck, sendOtp, verifyOtp } from "../redux/actions/authActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom/dist";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { PANEL_API_URL } from "../config";

export default function Signin() {
  const [sendotp, setsendotp] = React.useState("block");
  const [verifyotp, setverifyotp] = React.useState("none");
  const [number, setNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const countNum = 120;
  const [count, setcount] = useState(countNum);
  const [readOnly, setReadOnly] = useState(false);
  const [countrycode, setCountryCode] = useState("IN");
  const [numberError, setNumberError] = useState(false);
  const [otpcode, setOtpCode] = useState("");
  const [Error, setErr] = useState(false);
  const [seeOtp, setSeeOtp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const send_otp = () => {
  //   if (sendotp === "block") {
  //     setsendotp("none");
  //     setverifyotp("block");
  //     setcount(countNum);
  //     handleCount();
  //   }
  // };

  const data = useLocation();
  useEffect(() => {
    console.log("data?.state", data?.state);
    if (data?.state) {
      setNumber(data?.state?.phone);
      setFormattedPhoneNumber(data?.state?.formattedPhoneNumber);
    }
  }, [data?.state]);

  const handleCount = () => {
    setcount(countNum);
  };

  // number input
  const handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "");
    let formattedNumber = formattedValue;
    // Format the phone number with a hyphen after 5 characters for display

    if (formattedValue.length > 5) {
      formattedNumber =
        formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (event.key === "Backspace") {
      // Handle backspace key
      if (formattedValue.length === 6 && formattedValue.charAt(5) === "-") {
        // Remove hyphen when deleting the 6th character
        formattedNumber = formattedValue.slice(0, 5);
      }
      if (formattedValue.length === 5) {
        // Remove last character when deleting the 5th character
        formattedNumber = formattedValue.slice(0, 4);
      }
    }
    if (formattedValue.length <= 10) {
      setFormattedPhoneNumber(formattedNumber);
      setNumber(formattedValue);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setcount((oldCount) => {
        if (oldCount === 1) {
          setReadOnly(false);
        }
        return Math.max(oldCount - 1, 0);
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const change_num = () => {
    if (sendOtp === "none") {
      setsendotp("block");
      setverifyotp("none");
    } else {
      setsendotp("block");
      setverifyotp("none");
    }
  };

  const onHandleChangeOtp = (event) => {
    if (
      /^[0-9]*$/.test(event.target.value) &&
      event.target.value.toString().length <= 6
    ) {
      setOtpCode(event.target.value);
      setErr(validateOtp(event.target.value));
    }
  };

  //otp send
  const send_otp = (e, flag = true) => {
    e.preventDefault();
    if (validateValues(number) === true) {
      let obj = {
        phone: country_list[countrycode].phone + number,
        method: "text",
      };
      dispatch(
        phonecheck({ phone: country_list[countrycode].phone + number })
      ).then((res) => {
        if (res.status) {
          dispatch(sendOtp(obj)).then((res) => {
            if (flag) {
              if (res) {
                if (sendotp === "block") {
                  setsendotp("none");
                  setverifyotp("block");
                  setcount(countNum);
                  handleCount();
                } else {
                  setsendotp("block");
                  setverifyotp("none");
                }
              }
            }
            setReadOnly(true);
            setcount(countNum);
            handleCount();
            generatePopup("success", "OTP sent successfully !");
          });
        } else {
          navigate("/signup", {
            state: {
              phone: number,
              formattedPhoneNumber: formattedPhoneNumber,
            },
          });
          return generatePopup("error", "Mobile number not registered");
        }
      });
    } else {
      setNumberError(validateValues(number));
      return generatePopup("error", "OTP not sent !");
    }
  };

  //verify otp
  const signinform = (e) => {
    e.preventDefault();
    if (!validateOtp(otpcode) === true) {
      let obj = {
        phone: country_list[countrycode].phone + number,
        otp: otpcode,
      };
      const mode = localStorage.getItem("mode");
      dispatch(verifyOtp(obj, navigate))
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("authToken", res?.data?.access);
            localStorage.setItem("phone", res?.data?.phone);
            window.location.replace(
              `${PANEL_API_URL}?token=${res?.data?.access}&mode=${mode}&phone=${res?.data?.phone}`
            );
          } else if (res?.response?.data?.status === "False") {
            navigate("/signup");
          }
        })
        .catch((error) => {
          console.log("otp not verify :>> ", error.message);
        });
    } else {
      setErr("Please enter valid OTP");
    }
  };

  let country_array = [];
  let country_label = {};
  let country_labelName = {};
  let array = Object.keys(country_list).map((code, index) => {
    const country = country_list[code];
    country_array.push(code);
    country_label[code] = "+" + country.phone;
    country_labelName[code] = " " + country.name;
  });

  return (
    <>
      <Box className="signin_form">
        <Box className="signin_form_left">
          <img src={signin} height="100%" width="100%" />
        </Box>
        <Box className="signin_form_right">
          <Box className="sign_form_element border-gradient">
            {/* <Box className='sign_form_logo'>
              <img src={logo} height='100%' width='100%' />
            </Box> */}
            <Box className="welcome_tagLine">
              <Typography component={"h5"}>Welcome Back</Typography>
              <Typography component={"p"}>Continue to TRADE ARTH</Typography>
            </Box>
            <Box className="mobile_to_login">
              {/* Enter Mobile Number */}
              <Box className="form_control mrt-20" sx={{ display: sendotp }}>
                <Typography component={"h3"}>Sign in</Typography>
                <Typography component={"label"} className="label">
                  Enter Mobile Number
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <FormControl className='dropdown-ta small' sx={{ marginRight: 1 }}>
                    <Select
                      value={countryCode}
                      onChange={countryDrp}
                      className='dropdown' >
                      {Object.keys(country_list).map((code, index) => {
                        const country = country_list[code];
                        return (
                          <MenuItem key={index} value={index === 0 ? ' ' : country.phone}>
                            +{country.phone}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <ReactFlagsSelect
                    selected={countrycode}
                    countries={country_array}
                    customLabels={country_label}
                    onSelect={(code) => {
                      setCountryCode(code);
                    }}
                    placeholder=" "
                  />
                  <InputBase
                    value={formattedPhoneNumber}
                    type="phone"
                    placeholder="Enter mobile number"
                    fullWidth
                    onChange={handleChange}
                  />
                </Box>
                <Button
                  className="btn-ta filled"
                  fullWidth
                  sx={{ marginTop: 5 }}
                  onClick={send_otp}
                >
                  SEND OTP
                </Button>

                <Box className="accoutnHere">
                  <Typography component={"p"}>
                    Don't have an Account ?
                  </Typography>
                  <NavLink component={"a"} className="signIn_go" to={"/signup"}>
                    {" "}
                    Sign up
                  </NavLink>
                </Box>
              </Box>

              {/* Enter OTP */}
              <Box className="form_control mrt-20" sx={{ display: verifyotp }}>
                <Typography component={"h3"}>Login</Typography>
                <Box className="otp_send_success border-gradient">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.27757 10.3222L7.69905 17.9908C12.2813 10.652 17.4263 4.22019 22.089 0.509541C23.1341 -0.479967 24.7419 0.0972463 23.6164 1.16921C18.391 6.69396 14.3715 12.9608 10.2716 22.7735C8.66374 23.9279 6.81476 24.5051 5.93046 23.4331L0.142332 12.7959C-0.661574 11.4766 2.1521 8.4256 3.27757 10.3222Z"
                      fill="black"
                    />
                  </svg>
                  <Box className="otp_content">
                    <Typography component={"p"}>
                      An OTP has been sent to your number on{" "}
                      <Typography component={"p"}>
                        {" "}
                        {" +" + country_list[countrycode].phone + number}.
                      </Typography>
                    </Typography>
                    <Typography component={"p"}>
                      Please enter the OTP below to continue.
                    </Typography>
                  </Box>
                </Box>
                <Typography component={"label"} className="label">
                  Enter OTP
                </Typography>
                <Box className="resend_otp">
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className="actions_otp"
                  >
                    <Button
                      onClick={() => {
                        setSeeOtp(!seeOtp);
                      }}
                      className="otp_viewHide"
                    >
                      {seeOtp ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityRoundedIcon />
                      )}
                    </Button>
                    <Button
                      className="resend_otp_btn"
                      disabled={readOnly}
                      style={{
                        color: readOnly === true ? "gray" : "#0a53b5",
                      }}
                      onClick={(e) => send_otp(e, false)}
                    >
                      RESEND OTP
                    </Button>
                  </Box>
                  <InputBase
                    id="otp"
                    value={otpcode}
                    type={seeOtp ? "number" : "password"}
                    placeholder="Enter OTP"
                    onChange={onHandleChangeOtp}
                    fullWidth
                  />
                </Box>
                {Error ? <p className="color">{Error}</p> : ""}
                {readOnly && (
                  <Typography component={"p"} className="valide_otp">
                    You can resend the OTP in
                    <Typography component={"span"}>{count}</Typography>
                    second
                  </Typography>
                )}
                <Box
                  sx={{
                    display: { xs: "block", md: "flex" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 4,
                  }}
                >
                  <Button
                    className={
                      otpcode.length === 6 ? "btn-ta filled" : "btn-ta disabled"
                    }
                    disabled={!(otpcode.length === 6)}
                    fullWidth
                    sx={{ marginTop: 1, marginRight: { xs: 0, md: 1 } }}
                    onClick={signinform}
                  >
                    VERIFY OTP
                  </Button>
                  <Button
                    className="btn-ta outlined"
                    fullWidth
                    onClick={change_num}
                    sx={{ marginTop: 1, marginLeft: { xs: 0, md: 1 } }}
                  >
                    CHANGE NUMBER
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
