import React from 'react';
import { Box, Tooltip, Typography, Button, Menu, MenuItem, FormControl, Select, InputBase } from '@mui/material'
import './Contactbox.scss';
import wp from '../images/wp.png';

export default function Contactbox(props) {
  const help = <Typography sx={{ fontSize: '1.4rem' }}>Help Center</Typography>
  const btp = <Typography sx={{ fontSize: '1.4rem' }}>Bottom To Top</Typography>
  const [report, setreport] = React.useState(' ');
  const [helpcenter, sethelpcenter] = React.useState('none');
  const helpselect = ['Select Department', 'Sales Department', 'Support Department', 'Technical Department', 'Media Department', 'Account Department']
  // Dropdown
  const drpchnage = (event) => {
    setreport(event.target.value);
  };

  // Help Center
  const showHelpCenter = () => {
    if (helpcenter === 'none') {
      sethelpcenter('block')
    } else {
      sethelpcenter('none')
    }
  }
  return (
    <>
      <Box
        className="help_center"
        sx={{ bottom: props.showTopBtn, right: "3rem" }}
      >
        {/* <Tooltip title={help} arrow placement='left'>
          <Button className="help_button" onClick={showHelpCenter}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.2 24.6C31.7834 23.1833 32.1 21.6667 32.1 20C32.1 18.8 31.9167 17.65 31.6 16.5833C30.5167 16.8333 29.3834 16.9667 28.2 16.9667C25.7767 16.9693 23.3882 16.3892 21.2359 15.2754C19.0837 14.1616 17.2308 12.5466 15.8334 10.5667C14.3385 14.1836 11.5186 17.093 7.95004 18.7C7.88337 19.1167 7.88337 19.5667 7.88337 20C7.88337 21.5912 8.19678 23.1668 8.8057 24.6369C9.41462 26.1069 10.3071 27.4427 11.4323 28.5678C13.7046 30.8401 16.7865 32.1167 20 32.1167C21.75 32.1167 23.4334 31.7333 24.95 31.05C25.9 32.8667 26.3334 33.7667 26.3 33.7667C23.5667 34.6833 21.45 35.1333 20 35.1333C15.9667 35.1333 12.1167 33.55 9.28337 30.7C7.56004 28.982 6.27899 26.8717 5.55004 24.55H3.33337V16.9667H5.15004C5.70044 14.2875 6.96586 11.8074 8.81202 9.78933C10.6582 7.7713 13.0162 6.29069 15.6359 5.50461C18.2556 4.71853 21.0393 4.6563 23.6915 5.32452C26.3437 5.99275 28.7655 7.3665 30.7 9.30001C32.8004 11.3921 34.2331 14.0602 34.8167 16.9667H36.6667V24.55H36.5667L30.6334 30L21.8 29V26.2167H29.85L31.2 24.6Z" fill="white" />
              <g opacity="0.5">
                <path d="M16.7834 20.1834C16.4334 19.8167 15.95 19.6167 15.45 19.6167C14.4 19.6167 13.55 20.4667 13.55 21.5167C13.55 22.5667 14.4 23.4 15.45 23.4C15.95 23.4 16.4334 23.2 16.7834 22.85C17.1351 22.4954 17.3325 22.0162 17.3325 21.5167C17.3325 21.0172 17.1351 20.538 16.7834 20.1834Z" fill="white" />
                <path d="M26.4168 21.5167C26.4168 20.4667 25.5834 19.6167 24.5334 19.6167C24.0295 19.6167 23.5462 19.8169 23.1899 20.1732C22.8336 20.5295 22.6334 21.0128 22.6334 21.5167C22.6334 22.5667 23.4834 23.4 24.5334 23.4C25.5834 23.4 26.4168 22.5667 26.4168 21.5167Z" fill="white" />
              </g>
            </svg>
          </Button>
        </Tooltip> */}

        <Box className="help_center_box" sx={{ display: helpcenter }}>
          <Box className="help_center_header">
            <Typography component={"h5"}>Help Center</Typography>
            <Button className="close_help_center" onClick={showHelpCenter}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12L7 7M12 12L17 17M12 12L17 7M12 12L7 17"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Box>
          <Typography component={"p"} className="help_tag_line">
            Submit your query and the team will get back to you.
          </Typography>

          <Box className="form_control mrt-20">
            <FormControl className="dropdown-ta">
              <Select value={report} onChange={drpchnage} className="dropdown">
                {helpselect.map((item, index) => (
                  <MenuItem value={index === 0 ? " " : index} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="form_control mrt-20">
            <InputBase type="text" placeholder="Name" />
          </Box>
          <Box className="form_control mrt-20">
            <InputBase type="number" placeholder="Mobile Number" />
          </Box>
          <Box className="form_control mrt-20">
            <InputBase type="email" placeholder="Email" />
          </Box>
          <Box className="form_control mrt-20">
            <InputBase type="text" placeholder="Note" />
          </Box>
          <Box className="form_control mrt-20">
            <Button className="btn-ta filled" fullWidth>
              SUBMIT
            </Button>
          </Box>
        </Box>

        {/* <Typography
          component={"a"}
          href={"https://wa.aisensy.com/lgxLpy"}
          target={"blank"}
          className="wp_button"
        >
          <img src={wp} />
        </Typography> */}

        <Tooltip title={btp} arrow placement="left">
          <Button
            className="bottom_to_top help_button outlined"
            onClick={props.goToTop}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.92 15.8C19.73 15.8 19.54 15.73 19.39 15.58L12.87 9.06002C12.39 8.58002 11.61 8.58002 11.13 9.06002L4.61002 15.58C4.32002 15.87 3.84002 15.87 3.55002 15.58C3.26002 15.29 3.26002 14.81 3.55002 14.52L10.07 8.00002C11.13 6.94002 12.86 6.94002 13.93 8.00002L20.45 14.52C20.74 14.81 20.74 15.29 20.45 15.58C20.3 15.72 20.11 15.8 19.92 15.8Z"
                fill="#0A53B5"
              />
            </svg>
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}
