import { Alert, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputWithError from '../components/InputWithError';
import contact from '../images/contact-us.png';
import { sendMessage } from '../redux/actions/authActions';
import { generatePopup } from '../utils/popup';

export default function Contact() {
	const [firstname, setfirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [errors, setErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const dispatch = useDispatch();

	const validateForm = () => {
		const newErrors = {};

		// Regex patterns
		const namePattern = /^[A-Za-z]{3,10}$/;
		const lastNamePattern = /^[A-Za-z]{3,15}$/;
		const emailPattern = /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
		const mobilePattern = /^[0-9]{10}$/;
		const messagePattern = /^[A-Za-z0-9 ]{25,250}$/;

		if (!namePattern.test(firstname)) {
			newErrors.firstname =
				'First name should be 3 to 10 alphabetic characters';
		}

		if (!lastNamePattern.test(lastname)) {
			newErrors.lastname = 'Last name should be 3 to 15 alphabetic characters';
		}

		if (!emailPattern.test(email)) {
			newErrors.email = 'Invalid email address';
		}

		if (!mobilePattern.test(phone)) {
			newErrors.phone = 'Mobile number should be 10 digits';
		}

		if (!messagePattern.test(message)) {
			newErrors.message = 'Message should be 25 to 250 alphanumeric characters';
		}

		setErrors(newErrors);

		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = () => {
		setIsSubmitted(true);
		if (validateForm()) {
			dispatch(
				sendMessage({
					firstname,
					lastname,
					email,
					phone: phone,
					message,
				})
			).then((res) => {
				if (res?.data?.message) {
					generatePopup('success', 'Message Sent Successfully');
				}
			});
		}
	};

	useEffect(() => {
		if (isSubmitted) {
			validateForm();
		}
	}, [firstname, isSubmitted, lastname, email, phone, message]);

	return (
    <>
      {console.log("errors", errors)}
      <Box component={"section"} className="hero_section hero_section_small">
        <img src={contact} />

        <Box className="hero_content">
          <Box className="container">
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} xl={5}>
                <Typography component={"h2"}>
                  Contact <Typography component={"span"}>Us</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box component={"section"}>
        <Box className="container ">
          <Typography component={"h3"} className="sec_title">
            Get in <Typography component={"span"}>Touch</Typography>
          </Typography>
          <Typography component={"p"} className="common_text">
            Please call or email and we will be happy to assist you.
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box className="getIn_touch border-gradient">
              <Box className="getIn_touch_icon">
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.62 12.1442C17.19 12.1442 16.85 11.7594 16.85 11.2976C16.85 10.8908 16.48 10.0442 15.86 9.30759C15.25 8.59293 14.58 8.17513 14.02 8.17513C13.59 8.17513 13.25 7.79031 13.25 7.32853C13.25 6.86675 13.6 6.48193 14.02 6.48193C15.02 6.48193 16.07 7.07565 16.99 8.14214C17.85 9.14267 18.4 10.3851 18.4 11.2866C18.4 11.7594 18.05 12.1442 17.62 12.1442Z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M21.23 12.1439C20.8 12.1439 20.46 11.759 20.46 11.2973C20.46 7.39412 17.57 4.22763 14.03 4.22763C13.6 4.22763 13.26 3.84281 13.26 3.38103C13.26 2.91925 13.6 2.52344 14.02 2.52344C18.42 2.52344 22 6.45956 22 11.2973C22 11.759 21.65 12.1439 21.23 12.1439Z"
                    fill="#FFFFFF"
                  />
                  <path
                    opacity="0.4"
                    d="M11.79 15.948L8.52 19.5433C8.16 19.1915 7.81 18.8287 7.47 18.4548C6.44 17.3114 5.51 16.113 4.68 14.8596C3.86 13.6062 3.2 12.3528 2.72 11.1103C2.24 9.85695 2 8.65852 2 7.51506C2 6.76742 2.12 6.05276 2.36 5.39307C2.6 4.72239 2.98 4.10668 3.51 3.55695C4.15 2.86428 4.85 2.52344 5.59 2.52344C5.87 2.52344 6.15 2.58941 6.4 2.72134C6.66 2.85328 6.89 3.05119 7.07 3.33705L9.39 6.93234C9.57 7.20721 9.7 7.46009 9.79 7.70197C9.88 7.93286 9.93 8.16375 9.93 8.37265C9.93 8.63653 9.86 8.9004 9.72 9.15328C9.59 9.40616 9.4 9.67003 9.16 9.93391L8.4 10.8025C8.29 10.9234 8.24 11.0664 8.24 11.2423C8.24 11.3302 8.25 11.4072 8.27 11.4952C8.3 11.5831 8.33 11.6491 8.35 11.7151C8.53 12.0779 8.84 12.5507 9.28 13.1224C9.73 13.6941 10.21 14.2768 10.73 14.8596C11.09 15.2444 11.44 15.6182 11.79 15.948Z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M21.97 20.478C21.97 20.7858 21.92 21.1047 21.82 21.4125C21.79 21.5005 21.76 21.5885 21.72 21.6764C21.55 22.0722 21.33 22.446 21.04 22.7979C20.55 23.3916 20.01 23.8204 19.4 24.0953C19.39 24.0953 19.38 24.1063 19.37 24.1063C18.78 24.3701 18.14 24.5131 17.45 24.5131C16.43 24.5131 15.34 24.2492 14.19 23.7104C13.04 23.1717 11.89 22.446 10.75 21.5335C10.36 21.2146 9.97001 20.8958 9.60001 20.5549L12.87 16.9597C13.15 17.1905 13.4 17.3665 13.61 17.4874C13.66 17.5094 13.72 17.5424 13.79 17.5754C13.87 17.6084 13.95 17.6193 14.04 17.6193C14.21 17.6193 14.34 17.5534 14.45 17.4324L15.21 16.6078C15.46 16.333 15.7 16.1241 15.93 15.9921C16.16 15.8382 16.39 15.7612 16.64 15.7612C16.83 15.7612 17.03 15.8052 17.25 15.9042C17.47 16.0031 17.7 16.146 17.95 16.333L21.26 18.9167C21.52 19.1146 21.7 19.3455 21.81 19.6204C21.91 19.8953 21.97 20.1701 21.97 20.478Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Box>
              <Typography component={"p"}>+91 9321446611</Typography>
              <Typography component={"p"}>+91 9321446622</Typography>
              <Typography component={"p"}>+91 9321446633</Typography>
            </Box>
            <Box className="getIn_touch border-gradient">
              <Box className="getIn_touch_icon">
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17 23.0104H7C4 23.0104 2 21.3612 2 17.5131V9.81672C2 5.96855 4 4.31934 7 4.31934H17C20 4.31934 22 5.96855 22 9.81672V17.5131C22 21.3612 20 23.0104 17 23.0104Z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M12 14.6216C11.16 14.6216 10.31 14.3358 9.66003 13.7531L6.53002 11.0044C6.21002 10.7185 6.15003 10.2017 6.41003 9.84991C6.67003 9.49807 7.14003 9.43211 7.46003 9.71797L10.59 12.4667C11.35 13.1373 12.64 13.1373 13.4 12.4667L16.53 9.71797C16.85 9.43211 17.33 9.48708 17.58 9.84991C17.84 10.2017 17.79 10.7295 17.46 11.0044L14.33 13.7531C13.69 14.3358 12.84 14.6216 12 14.6216Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Box>
              <Typography component={"p"}>contact@tradearth.in</Typography>
            </Box>
            <Box className="getIn_touch border-gradient">
              <Box className="getIn_touch_icon">
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M20.62 9.65214C19.57 4.57256 15.54 2.28564 12 2.28564C12 2.28564 12 2.28564 11.99 2.28564C8.46 2.28564 4.42 4.56156 3.37 9.64114C2.2 15.3144 5.36 20.1192 8.22 23.1427C9.28 24.2642 10.64 24.8249 12 24.8249C13.36 24.8249 14.72 24.2642 15.77 23.1427C18.63 20.1192 21.79 15.3254 20.62 9.65214Z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M12 15.1601C13.7397 15.1601 15.15 13.6095 15.15 11.6967C15.15 9.78399 13.7397 8.2334 12 8.2334C10.2603 8.2334 8.85001 9.78399 8.85001 11.6967C8.85001 13.6095 10.2603 15.1601 12 15.1601Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Box>
              <Typography component={"p"}>
                Office No.311, 3rd floor, NMS Icon, Plot No. 194, Sector- 19,
                Ulwe Navi Mumbai 410206
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box component={"section"}>
        <Box className="container ">
          <Typography component={"h3"} className="sec_title">
            Contact <Typography component={"span"}>us</Typography>
          </Typography>
          <Typography component={"p"} className="common_text">
            Please fill up the following information :-
          </Typography>

          <Box className="input_form border-gradient">
            <Grid container spacing={6}>
              <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputWithError
                      label="First Name"
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      placeholder="Enter First Name"
                      error={errors.firstname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputWithError
                      label="Last Name"
                      error={errors.lastname}
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Enter Last Name"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputWithError
                      label="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                      error={errors.email}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputWithError
                      label="Mobile Number"
                      type="number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter your mobile number"
                      error={errors.phone}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={7}>
                <InputWithError
                  label="Message"
                  placeholder="Enter your message"
                  multiline
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={12}
                  error={errors.message}
                />
                <Box className="form_control checkBox mrt-20">
                  <Button onClick={handleSubmit} className="btn-ta filled">
                    Send Message
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* <Snackbar
				open={open}
				// autoHideDuration={3000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
					{alertMsg ? alertMsg : 'Message Sent Successfully'}
				</Alert>
			</Snackbar> */}
    </>
  );
}
