import React, { useEffect, useCallback, useRef, useState } from "react";
import sampleCSV from "../sampleFiles/sample.csv";
import { debounce, cloneDeep } from "lodash";
import PapaParse from "papaparse";
import {
  Box,
  Typography,
  Button,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  Dialog, 
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import "./affiliate.scss";
import CloseIcon from "@mui/icons-material/Close";
import ReactFlagsSelect from "react-flags-select";
import affiliatepng from "../images/affiliate.png";
import logo from "../images/dematade_logo.svg";
import { country_list } from "../Signin/countryList";
import digital_signature from "../images/digital-signature.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { validateAffiliate } from "../validation/affiliate";
import { useDispatch } from "react-redux";
import { generatePopup } from "../utils/popup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createaffiliate,
  getPaymentDetails,
  checkPhone,
  checkbanknumber,
  checkemail,
  checkgstin,
  checkrefercode,
  checkPanNumber,
} from "../redux/actions/affiliateActions";
import dayjs from "dayjs";

export default function Affilate() {
  const bankAcType = ["SAVING", "CURRENT"];
  const [open, setOpen] = useState(false);
  const [openotp, setOpenotp] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isAffiliateSubmitEnabled, setIsAffiliateSubmitEnabled] =
    useState(false);
  const [isPartnerSuccessfullyCreated, setIsPartnerSuccessfullyCreated] =
    useState(false);
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);
  const initialAffiliateData = {
    phone: "",
    refercode: "",
    broker: "DISTRIBUTOR",
    firstname: "",
    lastname: "",
    email: "",
    accounttype: "SAVING",
    bankname: "",
    ifsccode: "",
    banknumber: "",
    branch_name: "",
    bank_city: "",
    dob: "",
    pan_number: "",
    gstin: "",
    mrp: "",
    state: "",
    referby: "",
  };
  const [affiliateData, setAffiliateData] = useState(initialAffiliateData);
  const keys = {
    mobilenumber: "phone",
    createrefercode: "refercode",
    firstname: "firstname",
    lastname: "lastname",
    emailid: "email",
    bankaccounttype: "accounttype",
    bankname: "bankname",
    ifsccode: "ifsccode",
    bankaccountnumber: "banknumber",
    branchname: "branch_name",
    bankcity: "bank_city",
    dateofbirth: "dob",
    pannumber: "pan_number",
    gstin: "gstin",
    mrp: "mrp",
    state: "state",
    affiliateas: "broker",
    referalby: "referby",
  };
  const [fielderror, setFieldError] = useState({});
  const [commitment, setCommitment] = useState(false);
  const [countrycode, setCountryCode] = useState("IN");
  const countNum = 120;
  const [count, setcount] = useState(countNum);
  const [readOnly, setReadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  // const [paymentData, setPaymentData] = useState({});
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const uploadInputRef = useRef();
  const [csvFile, setCSVFile] = useState();
  const [isCSVDialogOpen, setIsCSVDialogOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const data = useLocation();
  const params = new URLSearchParams(data?.search).get("referal_code");
  const [seconds, setSeconds] = useState(60);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  useEffect(() => {
    if (params) {
      setAffiliateData((prevData) => ({
        ...prevData,
        referby: params,
      }));
    }
  }, [params]);

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Daman & Diu",
    "Jammu & Kashmir",
    "Lakshadweep",
    "Chandigarh",
    "Delhi NCR",
    "Ladakh",
    "Pondicherry",
  ];
  const [fullOTP, setFullOtp] = useState("");
  const dispatch = useDispatch();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setCommitment(false);
    setOpen(false);
  };
  const handleClickOpenotp = (scrollType) => {
    setOpenotp(true);
    setScroll(scrollType);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
    setOTP(["", "", "", "", "", ""]);
  };

  useEffect(() => {
    if (commitment === true || isCSVUploaded === true) {
      const error = validateAffiliate(affiliateData);
      setFieldError((prev) => {
        return { ...prev, ...error };
      });
    }
  }, [affiliateData, commitment]);

  const isErrors = (errors) => {
    const keys = Object.keys(errors);
    let isErrorBool = false;

    if (keys?.length === 0) {
      isErrorBool = false;
    } else {
      for (let i = 0; i < keys.length; i++) {
        const val = errors[keys[i]];
        if (val?.trim().length !== 0) {
          isErrorBool = true;
          break;
        } else {
          isErrorBool = false;
        }
      }
    }

    return isErrorBool;
  };

  useEffect(() => {
    console.log("fielderror", fielderror, "isErrors", isErrors(fielderror));
    if (commitment === true && !isErrors(fielderror)) {
      setIsAffiliateSubmitEnabled(true);
    } else {
      setIsAffiliateSubmitEnabled(false);
    }
  }, [fielderror, commitment]);

  const handleStateChange = (e, value) => {
    setAffiliateData((prev) => ({
      ...prev,
      state: value,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setcount((oldCount) => {
        if (oldCount === 1) {
          setReadOnly(false);
        }
        return Math.max(oldCount - 1, 0);
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setFullOtp(otp.join("").trim());
  }, [otp]);

  // Dropdown
  const accountType = (event) => {
    setAffiliateData((prev) => ({
      ...prev,
      accounttype: event.target.value.toUpperCase(),
    }));
  };

  function handleResponse(res) {
    if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0300"
    ) {
      const apiData = {
        datetime: res?.paymentMethod?.paymentTransaction?.dateTime,
        token: res?.paymentMethod?.paymentTransaction?.identifier,
        txid: res?.merchantTransactionIdentifier,
        amount: res?.paymentMethod?.paymentTransaction?.amount,
        merchantcode: res?.merchantCode,
      };

      const checkoutModal = document.getElementById("checkoutmodal");
      const modalPage = document.querySelector(".modalpage");

      if (checkoutModal) {
        checkoutModal.classList.add("hide-checkout");
      }

      if (modalPage) {
        modalPage.classList.add("hide-checkout");
      }
      sendAffiliateData(apiData);
    } else if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0398"
    ) {
      // initiated block
    } else {
      // error block
    }
  }

  const handleError = () => {
    console.log("error----------------");
  };
  const handleSuccess = () => {
    console.log("success----------------");
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleFormSubmit = async () => {
    const phone = 91 + affiliateData?.phone?.replace(/-/g, "");
    const email = affiliateData?.email;

    const paymentdata = await getPaymentDetails({
      phone,
      email,
    });

    console.log("paymentdata", paymentdata);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      handleError();
      return;
    }

    //   {
    //     "razorpay_payment_id": "pay_OBS4VAJb5dXG4x",
    //     "status_code": 200
    // }

    const length = 12;
    const letters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCode = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      randomCode += letters[randomIndex];
    }
    console.log("randomCode", randomCode);
    const options = {
      key: "rzp_live_IVB2IdJP4ipwcz",
      // key: "rzp_test_1Tj4XwlZ1EPkau",
      currency: "INR",
      amount: (1 * 100).toString(),
      // order_id: randomCode,
      name: "Trade Arth",
      prefill: {
        name: affiliateData?.name || "",
        email: affiliateData?.email || "",
        contact: affiliateData?.phone || "",
      },
      handler: async (response) => {
        console.log("response", response);
        const apiData = {
          datetime: dayjs().format("DD-MM-YYYY HH:mm:ss"),
          payment_id: response?.razorpay_payment_id,
          amount: "1",
        };
        sendAffiliateData(apiData);
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    // console.log("paymentData :>> ", paymentdata);
    // if (paymentdata) {
    //   let reqJson = {
    //     features: {
    //       enableAbortResponse: true,
    //       enableExpressPay: true,
    //       enableInstrumentDeRegistration: true,
    //       enableMerTxnDetails: true,
    //       enableNewWindowFlow: true,
    //     },
    //     consumerData: {
    //       deviceId: "WEBSH1", //possible values "WEBSH1" or "WEBSH2"
    //       token: paymentdata?.token,
    //       responseHandler: handleResponse,
    //       paymentMode: "all",
    //       merchantLogoUrl:
    //         "https://emailwala.s3.ap-south-1.amazonaws.com/TradeArthLogofor+payment.png",
    //       merchantId: "L998500",
    //       currency: "INR",
    //       consumerId: paymentdata?.consumerid,
    //       consumerMobileNo: phone,
    //       consumerEmailId: email,
    //       txnId: paymentdata?.txnid,
    //       items: [
    //         {
    //           itemId: "first",
    //           amount: "1",
    //           comAmt: "0",
    //         },
    //       ],
    //       customStyle: {
    //         PRIMARY_COLOR_CODE: "#0a53b5", //merchant primary color code
    //         SECONDARY_COLOR_CODE: "#FFFFFF", //provide merchant's suitable color code
    //         BUTTON_COLOR_CODE_1: "#166bdd", //merchant's button background color code
    //         BUTTON_COLOR_CODE_2: "#FFFFFF", //provide merchant's suitable color code for button text
    //       },
    //     },
    //   };

    //   if (window.$.pnCheckout) {
    //     window.$.pnCheckout(reqJson);
    //     if (reqJson.features.enableNewWindowFlow) {
    //       window.pnCheckoutShared.openNewWindow();
    //     }
    //   }
    // }
    // const config = {
    //   root: "",
    //   flow: "DEFAULT",
    //   data: {
    //     orderId: "192673512",
    //     token: "12321",
    //     tokenType: "TXN_TOKEN",
    //     amount: "2000",
    //   },
    //   merchant: {
    //     redirect: false,
    //   },
    //   handler: {
    //     transactionStatus: (paymentStatus) => {
    //       console.log("paymentStatus : ", paymentStatus);
    //       const obj = {
    //         status: paymentStatus?.STATUS,
    //         method: paymentStatus?.PAYMENTMODE,
    //         amount: paymentStatus?.TXNAMOUNT,
    //         gst: 0 || "",
    //         gateway_fee: 0,
    //         total_amount: paymentStatus?.TXNAMOUNT,
    //         order_id: paymentStatus?.ORDERID,
    //         payment_id: paymentStatus?.TXNID,
    //         payment_date: dayjs(paymentStatus?.TXNDATE).format("YYYY-MM-DD"),
    //         plan: "basic",
    //         phone_type: "iphone",
    //         merchantcode: "435",
    //         txid: paymentStatus?.TXNID,
    //         token: "rrtr",
    //       };
    //       // dispatch(getPaytmInvoice(obj)).then((res) => {
    //       //   console.log("res == == == ", res);
    //       //   if (res?.data?.status === true) {
    //       //     console.log("entered");
    //       //     window.location.href = `${PANEL_API_URL}status?status=true&orderid=${
    //       //       paymentStatus?.ORDERID
    //       //     }&txnid=${paymentStatus?.TXNID}&plan=${
    //       //       payload?.plan
    //       //     }&amount=${paymentStatus?.TXNAMOUNT}&date=${dayjs(
    //       //       paymentStatus?.TXNDATE
    //       //     ).format("YYYY-MM-DD HH:mm:ss")}`;
    //       //   } else {
    //       //     window.location.href = `${PANEL_API_URL}status?status=false`;
    //       //   }
    //       // });
    //     },
    //     notifyMerchant: (eventName, data) => {
    //       console.log("Handler", eventName, data);
    //       if (eventName === "SESSION_EXPIRED") {
    //         alert("Your session has expired!!");
    //         // window.location.reload();
    //       }
    //     },
    //   },
    // };

    // if (window.Paytm && window.Paytm.CheckoutJS) {
    //   window.Paytm.CheckoutJS.init(config)
    //     .then(() => {
    //       window.Paytm.CheckoutJS.invoke();
    //     })
    //     .catch((error) => {
    //       console.log("Error => ", error);
    //     });
    // } else {
    //   console.log("something went wrong");
    // }
  };

  // format date
  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);

      // Extract year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      const dateFormatted = `${year}-${month}-${day}`;

      const minYear = 1900;

      if (year < minYear) {
        fielderror.dob = "Invalid year selected";
      } else {
        fielderror.dob = "";
      }

      if (!inputDate) {
        fielderror.dob = "Dob is required.";
      } else {
        const dobDate = new Date(inputDate);

        const currentDate = new Date();

        let age = currentDate.getFullYear() - dobDate.getFullYear();

        if (
          currentDate.getMonth() < dobDate.getMonth() ||
          (currentDate.getMonth() === dobDate.getMonth() &&
            currentDate.getDate() < dobDate.getDate())
        ) {
          age--;
        }

        if (age < 18) {
          fielderror.dob = "You must be at least 18 years old.";
        }
      }

      setAffiliateData((prev) => ({
        ...prev,
        dob: dateFormatted,
      }));
    }
  }

  // Dropdown
  const brokerDrp = (event) => {
    setAffiliateData((prev) => ({
      ...prev,
      broker: event.target.value?.toUpperCase(),
    }));
  };

  const handleFirstName = (e) => {
    const { value } = e.target;
    const alphabeticPattern = /^[A-Za-z]+$/;
    if (value === "" || value.match(alphabeticPattern)) {
      if (!(value.length > 10)) {
        if (!value) {
          fielderror.firstname = "Enter first name";
        } else if (!/^[A-Za-z]+$/.test(value)) {
          fielderror.firstname = "First name only contain letters";
        } else if (value.length < 3 || value.length > 10) {
          fielderror.firstname = "First name: 3-10 characters";
        } else {
          fielderror.firstname = "";
        }
        setAffiliateData((prev) => ({
          ...prev,
          firstname: value,
        }));
      }
    }
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    const alphabeticPattern = /^[A-Za-z]+$/;
    if (value === "" || value.match(alphabeticPattern)) {
      if (!(value.length > 10)) {
        if (!value) {
          fielderror.lastname = "Enter last name";
        } else if (!/^[A-Za-z]+$/.test(value)) {
          fielderror.lastname = "Last name only contain letters";
        } else if (value.length < 3 || value.length > 10) {
          fielderror.lastname = "Last name: 3-10 characters";
        } else {
          fielderror.lastname = "";
        }
        setAffiliateData((prev) => ({
          ...prev,
          lastname: value,
        }));
      }
    }
  };

  const handleReferCode = async (e) => {
    const { value } = e.target;
    const alphanumericPattern = /^[A-Za-z0-9]+$/;

    if (value === "" || value.length <= 10) {
      setAffiliateData((prev) => ({
        ...prev,
        refercode: value ? value.toUpperCase() : "",
      }));
      debounceReferCode(value);
    }
  };

  const handleReferBy = (e) => {
    const { value } = e.target;
    const alphanumericPattern = /^[A-Za-z0-9]+$/;

    if (value === "" || value.match(alphanumericPattern)) {
      if (value.length <= 10) {
        setAffiliateData((prev) => ({
          ...prev,
          referby: value.toUpperCase(),
        }));
      }
    }
  };

  const getReferBy = (broker, referby) => {
    if (broker?.toUpperCase() === "SUB DISTRIBUTOR" && referby) {
      if (referby?.trim().length !== 0) {
        return referby;
      }
    }
    return "TRADEARTH";
  };

  const sendAffiliateData = (paymentData) => {
    setIsPaymentLoading(true);
    const newPhone = 91 + affiliateData?.phone?.replace(/-/g, "");
    const newAffiliateData = {
      ...affiliateData,
      ...paymentData,
      phone: newPhone,
      broker:
        affiliateData?.broker === "SUB DISTRIBUTOR"
          ? "Sub Distributor"
          : "Distributor",
      referby: getReferBy(),
      ...paymentData,
    };
    console.log("new Affiliated Data", newAffiliateData);
    // startTimer();
    // setIsLoading(true);
    dispatch(createaffiliate(newAffiliateData))
      .then((data) => {
        if (data?.status === true) {
          setIsPaymentLoading(false);
          setCommitment(false);
          setFieldError({});
          setAffiliateData(initialAffiliateData);
          setCountryCode("IN");
          setOTP(["", "", "", "", "", ""]);
          setIsSubmit(false);
          setIsPartnerSuccessfullyCreated(true);
        } else {
          setIsPaymentLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error in affiliate creating", err);
        setIsPaymentLoading(false);
        setIsLoading(false);
      });
  };

  const handleNumber = async (event) => {
    const { value } = event.target;

    const formattedValue = value.replace(/\D/g, "");
    let formattedNumber = formattedValue;

    if (formattedValue.length > 5) {
      formattedNumber =
        formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (event.key === "Backspace") {
      // Handle backspace key
      if (formattedValue.length === 6 && formattedValue.charAt(5) === "-") {
        // Remove hyphen when deleting the 6th character
        formattedNumber = formattedValue.slice(0, 5);
      }
      if (formattedValue.length === 5) {
        // Remove last character when deleting the 5th character
        formattedNumber = formattedValue.slice(0, 4);
      }
    }

    if (formattedValue.length <= 10) {
      setAffiliateData((prev) => ({
        ...prev,
        phone: formattedNumber,
      }));
    }

    if (!formattedNumber) {
      fielderror.phone = "Mobile is required.";
    } else if (formattedNumber.length < 10) {
      fielderror.phone = "Enter valid phone number ";
    } else {
      fielderror.phone = "";
      debouncePhone("91" + formattedNumber);
    }
  };

  const submitTooltip = (
    <Typography sx={{ fontSize: "1.4rem" }}>
      Please Enter All Required fields.
    </Typography>
  );

  const handleOtpChange = (e, index) => {
    const value = e?.key;

    if (value === "Backspace") {
      if (index !== 0) {
        const previousInput = inputRefs[index - 1]?.current;

        if (previousInput) {
          const inputLength = previousInput?.value?.length;
          previousInput.setSelectionRange(inputLength, inputLength);
          previousInput.focus();
        }
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOTP(newOtp);
    }

    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOTP(newOtp);

      if (value.length === 1 && index < inputRefs.length) {
        inputRefs[index + 1]?.current?.focus();
      }
    }
  };

  let country_array = [];
  let country_label = {};
  let country_labelName = {};
  let array = Object.keys(country_list).map((code, index) => {
    const country = country_list[code];
    country_array.push(code);
    country_label[code] = "+" + country.phone;
    country_labelName[code] = " " + country.name;
  });

  const debounceDuration = 500;

  const debounceBankNumber = useCallback(
    debounce(async (value) => {
      const res = await checkbanknumber({
        banknumber: value,
      });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.banknumber = "Account number is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.banknumber;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debouncePhone = useCallback(
    debounce(async (value) => {
      let newPhone = value.replace(/-/g, "");
      if (!newPhone.includes("91")) {
        newPhone = 91 + newPhone;
      }
      const res = await checkPhone({ phone: newPhone });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.phone = "Phone number is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.phone;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debounceEmail = useCallback(
    debounce(async (value) => {
      const res = await checkemail({ email: value });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.email = "Email is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.email;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debounceGSTIN = useCallback(
    debounce(async (value) => {
      const res = await checkgstin({
        gstin: value,
      });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.gstin = "GSTIN is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.gstin;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debounceReferCode = useCallback(
    debounce(async (value) => {
      const res = await checkrefercode({
        refercode: value,
      });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.refercode = "Refer Code is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.refercode;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debouncePanNumber = useCallback(
    debounce(async (value) => {
      const res = await checkPanNumber({
        pan_number: value,
      });

      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.pan_number = "Pan number is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.pan_number;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const handleFileChange = (e) => {
    if (e?.target?.files?.length) {
      setCSVFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleCSVUpload = () => {
    if (csvFile) {
      try {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
          const csv = PapaParse.parse(target.result, {
            header: true,
          });
          const parsedData = csv?.data[0];
          const rows = Object.keys(parsedData);
          const newData = {};
          for (let i = 0; i < rows.length; i++) {
            const key = rows[i];
            const ele = rows[i]?.replaceAll(" ", "")?.toLowerCase();
            const valKey = keys[ele];
            if (ele && parsedData[key]?.trim()?.length !== 0) {
              if (
                valKey === "accounttype" ||
                valKey === "broker" ||
                valKey === "referby" ||
                valKey === "pan_number" ||
                valKey === "gstin" ||
                valKey === "refercode"
              ) {
                newData[valKey] = parsedData[key]?.toUpperCase();
              } else {
                newData[valKey] = parsedData[key];
              }
            }
          }

          if (newData["phone"] && newData["phone"].substring(0, 2) === "91") {
            let newPhone = newData["phone"].substring(2);
            newData["phone"] = newPhone;
          }

          if (newData["broker"]) {
            newData["referby"] = getReferBy(
              newData["broker"],
              newData["referby"]
            );
          }

          if (newData["dob"]) {
            const date = newData["dob"];
            const arrDate = date.split("/");
            newData["dob"] = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
          }

          if (newData["banknumber"]) {
            debounceBankNumber(newData["banknumber"]);
          }
          if (newData["phone"]) {
            const phone = newData["phone"];
            if (phone.length > 5) {
              newData["phone"] = phone.slice(0, 5) + "-" + phone.slice(5);
            }
            debouncePhone(newData["phone"]);
          }

          if (newData["email"]) {
            debounceEmail(newData["email"]);
          }

          if (newData["gstin"]) {
            debounceGSTIN(newData["gstin"]);
          } else {
            newData["gstin"] = "";
          }

          if (newData["refercode"]) {
            debounceReferCode(newData["refercode"]);
          }

          if (newData["pan_number"]) {
            debouncePanNumber(newData["pan_number"]);
          }

          setFieldError(validateAffiliate(newData));
          setAffiliateData(newData);
          setIsCSVUploaded(true);
          setIsCSVDialogOpen(false);
          setCSVFile(null);
        };
        reader.readAsText(csvFile);
      } catch (err) {
        generatePopup("error", "Please try again");
      }
    } else {
      generatePopup("error", "Please select a file");
    }
  };

  return (
    <>
      {isPaymentLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: 999999999999999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span class="loader"></span>
        </Box>
      ) : (
        <>
          <Box
            component={"section"}
            className="hero_section hero_section_small"
          >
            <img src={affiliatepng} />

            {/* <Box className="hero_content">
          <Box className="container">
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} xl={5}> */}
            {/* <Typography component={"h2"}>
                  Our <Typography component={"span"}>Affiliate</Typography>
                </Typography> */}
            {/* </Grid>
            </Grid>
          </Box>
        </Box> */}
          </Box>
          <Box component={"section"}>
            <Box className="container">
              <Typography component={"h3"} className="sec_title">
                Become <Typography component={"span"}>Affiliate</Typography>
              </Typography>

              <Box className="input_form border-gradient">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    flexWrap: "wrap",
                  }}
                >
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        First Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter First Name"
                        value={
                          affiliateData?.firstname
                            ? affiliateData?.firstname
                            : ""
                        }
                        onChange={(e) => handleFirstName(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.firstname}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Last Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Last Name"
                        value={
                          affiliateData?.lastname ? affiliateData?.lastname : ""
                        }
                        onChange={(e) => handleLastName(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.lastname}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Email ID
                      </Typography>
                      <InputBase
                        type="email"
                        placeholder="Enter Email ID"
                        value={affiliateData?.email ? affiliateData?.email : ""}
                        onChange={async (e) => {
                          if (!e.target.value) {
                            fielderror.email = "Please enter email";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              e.target.value
                            )
                          ) {
                            fielderror.email = "Invalid email address";
                          } else if (
                            !(e.target.value.length >= 15) ||
                            !(e.target.value.length <= 40)
                          ) {
                            fielderror.email =
                              "Email length should be 15 to 40";
                          } else if (
                            !e.target.value.endsWith(".com") &&
                            !e.target.value.endsWith(".in")
                          ) {
                            fielderror.email = "Enter valid email";
                          } else {
                            fielderror.email = "";
                            debounceEmail(e.target.value);
                          }
                          setAffiliateData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.email}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Mobile Number
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ReactFlagsSelect
                          selected={countrycode}
                          countries={country_array}
                          customLabels={country_label}
                          onSelect={(code) => {
                            setCountryCode(code);
                          }}
                          placeholder=" "
                        />
                        <InputBase
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={affiliateData?.phone}
                          className="inputFiled"
                          id="MobileNumber"
                          name="number"
                          onChange={handleNumber}
                        />
                      </Box>
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.phone}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Bank Account Type
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          value={affiliateData?.accounttype?.toUpperCase()}
                          onChange={accountType}
                          className="dropdown"
                        >
                          {bankAcType.map((ele, index) => (
                            <MenuItem key={index} value={ele?.toUpperCase()}>
                              {ele?.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Bank Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Bank Name"
                        value={
                          affiliateData?.bankname ? affiliateData?.bankname : ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[A-Za-z\s]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 50)) {
                              if (!value) {
                                fielderror.bankname = "Bankname is required.";
                              } else if (!/^[A-Za-z ]+$/.test(value)) {
                                fielderror.bankname =
                                  "Bankname should be numeric value.";
                              } else if (
                                value.length > 50 ||
                                value.length < 3
                              ) {
                                fielderror.bankname =
                                  "Bankname length should be 3-50";
                              } else {
                                fielderror.bankname = "";
                              }
                              setAffiliateData((prev) => ({
                                ...prev,
                                bankname: value,
                              }));
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.bankname}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        IFSC Code
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter IFSC Code"
                        value={
                          affiliateData?.ifsccode ? affiliateData?.ifsccode : ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[A-Za-z0-9]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 11)) {
                              if (!value) {
                                fielderror.ifsccode = "Ifsc code is required.";
                              } else if (!/^[A-Za-z0-9]+$/.test(value)) {
                                fielderror.ifsccode =
                                  "Ifsc code should be numeric value.";
                              } else if (value.length > 11) {
                                fielderror.ifsccode =
                                  "Ifsc code length should be 11";
                              } else {
                                fielderror.ifsccode = "";
                              }
                              setAffiliateData((prev) => ({
                                ...prev,
                                ifsccode: value,
                              }));
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.ifsccode}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Bank Account Number
                      </Typography>
                      <InputBase
                        type="number"
                        placeholder="Enter Bank Account Number"
                        value={
                          affiliateData?.banknumber
                            ? affiliateData?.banknumber
                            : ""
                        }
                        onChange={async (e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[0-9]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 20)) {
                              if (!value) {
                                fielderror.banknumber =
                                  "Account Number is required.";
                              } else if (!/^[0-9]+$/.test(value)) {
                                fielderror.banknumber =
                                  "Account Number should be numeric value.";
                              } else if (value.length > 20) {
                                fielderror.banknumber =
                                  "Account Number length should be 20";
                              } else {
                                fielderror.banknumber = "";
                                debounceBankNumber(value);
                              }
                              setAffiliateData((prev) => ({
                                ...prev,
                                banknumber: value,
                              }));
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.banknumber}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Branch Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Branch Name"
                        value={
                          affiliateData?.branch_name
                            ? affiliateData?.branch_name
                            : ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setAffiliateData((prev) => ({
                            ...prev,
                            branch_name: value,
                          }));
                        }}
                      />

                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.branch_name}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Bank City
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Bank City"
                        value={
                          affiliateData?.bank_city
                            ? affiliateData?.bank_city
                            : ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[A-Za-z\s]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 50)) {
                              if (!value) {
                                fielderror.bank_city = "Bankcity is required.";
                              } else {
                                fielderror.bank_city = "";
                              }
                              setAffiliateData((prev) => ({
                                ...prev,
                                bank_city: value,
                              }));
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.bank_city}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Date of Birth
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="datePicker"
                          value={dayjs(affiliateData?.dob)}
                          format="DD/MM/YYYY"
                          inputFormat="YYYY-MM-DD"
                          onChange={(e) => {
                            formatDate(e);
                          }}
                        />
                      </LocalizationProvider>
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.dob}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        PAN Number
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter PAN Number"
                        value={
                          affiliateData?.pan_number
                            ? affiliateData?.pan_number
                            : ""
                        }
                        onChange={async (e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[A-Za-z0-9]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 10)) {
                              if (!value) {
                                fielderror.pan_number =
                                  "Pan number is required.";
                              } else if (!/^[A-Za-z0-9]+$/.test(value)) {
                                fielderror.pan_number =
                                  "Pan number should be alphanumeric value.";
                              } else if (value.length !== 10) {
                                fielderror.pan_number =
                                  "Pan number length should be 10";
                              } else {
                                fielderror.pan_number = "";
                                debouncePanNumber(value);
                              }
                              setAffiliateData((prev) => ({
                                ...prev,
                                pan_number: value?.toUpperCase(),
                              }));
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.pan_number}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        State
                      </Typography>
                      <Box className="dropdown-ap">
                        <Autocomplete
                          value={affiliateData?.state}
                          onChange={handleStateChange}
                          sx={{ padding: "0px" }}
                          className="autocomplete"
                          options={states}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                paddingRight: "0px",
                                padding: "0px",
                                width: "100%",
                              }}
                              placeholder="Select State"
                            />
                          )}
                        />
                        {fielderror && (
                          <span style={{ color: "red" }}>
                            {fielderror.state}
                          </span>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography component={"label"} className="label">
                        GSTIN
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter GSTIN"
                        value={affiliateData?.gstin ? affiliateData?.gstin : ""}
                        onChange={async (e) => {
                          const { value } = e.target;
                          const alphabeticPattern = /^[A-Za-z0-9]+$/;
                          if (value === "" || value.match(alphabeticPattern)) {
                            if (!(value.length > 15)) {
                              setAffiliateData((prev) => ({
                                ...prev,
                                gstin: value?.toUpperCase(),
                              }));
                              debounceGSTIN(value);
                            }
                          }
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.gstin}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        MRP
                      </Typography>
                      <InputBase
                        type="number"
                        placeholder="Enter MRP"
                        value={affiliateData?.mrp ? affiliateData?.mrp : ""}
                        onChange={(e) => {
                          if (!e.target.value) {
                            fielderror.mrp = "MRP is required.";
                          } else if (
                            e.target.value > 5000 ||
                            e.target.value < 1000
                          ) {
                            fielderror.mrp = "MRP should between 1000 - 5000";
                          } else {
                            fielderror.mrp = "";
                          }
                          setAffiliateData((prev) => ({
                            ...prev,
                            mrp: e.target.value,
                          }));
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.mrp}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Create Refer Code
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Create Refer Code"
                        value={
                          affiliateData?.refercode
                            ? affiliateData?.refercode
                            : ""
                        }
                        onChange={(e) => handleReferCode(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.refercode}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography component={"label"} className="label">
                        Referal by
                      </Typography>
                      <InputBase
                        disabled={
                          affiliateData?.broker?.toUpperCase() === "DISTRIBUTOR"
                            ? true
                            : false
                        }
                        type="text"
                        placeholder="Enter Refer Code"
                        value={affiliateData?.referby}
                        onChange={(e) => handleReferBy(e)}
                      />
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        AFFILIATE AS :
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          value={affiliateData?.broker?.toUpperCase()}
                          onChange={brokerDrp}
                          className="dropdown"
                        >
                          <MenuItem value="DISTRIBUTOR">DISTRIBUTOR</MenuItem>
                          <MenuItem value="SUB DISTRIBUTOR">
                            SUB DISTRIBUTOR
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Box className="form_control checkBox mrt-20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={commitment}
                        onChange={() => {
                          setOpen(true);
                        }}
                      />
                    }
                    label={
                      <Typography>
                        I have read the{" "}
                        <Typography
                          component={"span"}
                          onClick={handleClickOpen("paper")}
                        >
                          Affiliate agreement and Terms & Conditions
                        </Typography>
                      </Typography>
                    }
                  />
                </Box>
                {/* <Box className="form_control checkBox mrt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terms}
                    onChange={() => {
                      setTerms(!terms);
                    }}
                  />
                }
                label={<Typography>Terms & Condition</Typography>}
              />
            </Box> */}
                <Tooltip
                  title={commitment ? "" : submitTooltip}
                  arrow
                  placement="right"
                >
                  <div style={{ width: "max-content" }}>
                    <Box
                      className="form_control checkBox mrt-20"
                      style={{ display: "flex" }}
                    >
                      <Button
                        className={
                          isAffiliateSubmitEnabled
                            ? "btn-ta filled"
                            : "btn-ta disabled"
                        }
                        onClick={handleFormSubmit}
                        disabled={!isAffiliateSubmitEnabled}
                      >
                        Submit
                      </Button>
                      <Button
                        className="btn-ta filled"
                        style={{
                          marginLeft: "10px",
                        }}
                        onClick={() => setIsCSVDialogOpen(true)}
                      >
                        Upload CSV
                      </Button>
                    </Box>
                  </div>
                </Tooltip>
              </Box>

              <Box className="benefit border-gradient">
                <Typography className="charges-style" component={"p"}>
                  Affiliate registration charges are Rs 1/- with GST
                  Inclusive and registration charges are non-refundable. The
                  affiliate Payouts will be released on every 5th of the next
                  month.
                </Typography>

                <Typography className="heading-style" component={"h3"}>
                  Benefit of becoming as affiliate :-
                </Typography>

                <Typography component={"ul"}>
                  <Typography component={"li"}>
                    Sell your own algo trading product under your brand and
                    logo.
                  </Typography>
                  <Typography component={"li"}>
                    Promote your franchise link and social media links.
                  </Typography>
                  <Typography component={"li"}>
                    Get an admin dashboard to manage all your leads.
                  </Typography>
                  <Typography component={"li"}>
                    Access to a friendly trading panel and mobile1 apps for iOS
                    and Android.
                  </Typography>
                  <Typography component={"li"}>
                    Use the Strategy Builder to create your own strategies for
                    your customers.
                  </Typography>
                </Typography>

                <Typography component={"p"} className="desc text-grey">
                  &nbsp;
                </Typography>
                <Typography className="heading-style" component={"h3"}>
                  Payout Ratio for Subscription Charges :
                </Typography>
                <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Payout Split:{" "}
                  </Typography>
                  The monthly payout ratio for subscription charges is set at
                  50% to the affiliate and 50% to DeMatade Algo Technology
                  Solutions Private Limited.
                </Typography>
                <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Fixed Ratio:{" "}
                  </Typography>
                  Regardless of the number of active subscriptions, the payout
                  split on subscription charges will always be 50:50 between the
                  affiliate and DeMatade Algo Technology Solutions Private
                  Limited.
                </Typography>
                <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Calculation Basis:{" "}
                  </Typography>
                  Payouts will be calculated based on the total subscription
                  charges received during the month.
                </Typography>
                <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Payment Schedule:{" "}
                  </Typography>
                  Payouts will be processed according to the agreed-upon payment
                  schedule, typically on a monthly basis.
                </Typography>
                <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Contact Us:{" "}
                  </Typography>
                  If you have any questions or need further clarification
                  regarding the payout ratio for subscription charges, please
                  contact our finance department.
                </Typography>
                {/* <Typography className="p-style" component={"p"}>
                  <Typography component="span" className="span-style">
                    Below 10 active subscriptions:{" "}
                  </Typography>
                  <Typography component="span" className="blue-style">
                    60%{" "}
                  </Typography>
                  to the affiliate and{" "}
                  <Typography component="span" className="blue-style">
                    40%{" "}
                  </Typography>
                  to DeMatade Algo Technology Solutions Private Limited
                </Typography> */}
                <Typography component={"p"} className="desc text-grey">
                  &nbsp;
                </Typography>
                <Typography className="disclaimer-style" component={"p"}>
                  Disclaimer: DeMatade Algo Technology Solutions Private Limited
                  is not responsible for any trading profit or loss incurred by
                  affiliates or their customers. Prospective affiliates are
                  advised to read all related documents carefully before
                  joining. affiliates are also urged not to make any false
                  promises or statements to users regarding the system and
                  strategies. It's important for interested individuals to
                  carefully consider the terms and conditions before becoming a
                  affiliate. The payout structure seems to reward affiliates
                  based on their performance, which may provide an incentive to
                  grow their subscriber base. However, as with any business
                  opportunity, potential affiliates should exercise due
                  diligence and understand the risks involved in algo trading
                  and managing customer expectations.
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Dialog
        scroll={scroll}
        open={isPaymentDialogOpen}
        onClose={() => {
          setIsPaymentDialogOpen(false);
        }}
        className="dialogBox"
      >
        <DialogContent
          sx={{ padding: "0px", minHeight: "fit-content", overflow: "hidden" }}
        >
          <Box className="planCard premium">
            <Box
              onClick={() => {
                setIsPaymentDialogOpen(false);
              }}
              className="planCard_close_icon"
            >
              <CloseIcon />
            </Box>
            <Box className="planCard-type">
              <Box className="logo_wrapper">
                <img src={logo} />
              </Box>
              <Box className="logo_div">
                <Typography
                  component={"p"}
                  className="planType company_name"
                  sx={{ color: "#FFFFFF !important" }}
                >
                  DEMATADE ALGO TECHNOLOGY SOLUTIONS PRIVATE LIMITED
                </Typography>
                <Typography
                  component={"p"}
                  className="planType"
                  sx={{ color: "#FFFFFF !important" }}
                >
                  Affiliate Charges
                </Typography>
                <Box className="planPrice">
                  <Typography component={"span"}>₹</Typography>
                  <Typography
                    component={"span"}
                    sx={{ color: "#FFFFFF !important" }}
                  >
                    1
                  </Typography>
                  <Typography component={"span"}></Typography>
                </Box>
              </Box>
            </Box>
            <Box className="planCard-footer">
              {/* <GooglePayUPI handlePaymentResponse={handlePaymentResponse} /> */}
              <Typography
                sx={{
                  marginTop: 3,
                  fontSize: "1.1rem",
                }}
                component={"span"}
              >
                T&C: Inclusive GST & Payment Gateway Charges Applicable. Charges
                amount are Non-Refundable.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* <Dialog
				scroll={scroll}
				open={isPaymentSuccessDialogOpen}
				className='dialogBox payment_box'
			>
				<DialogContent
					sx={{ padding: '0px', minHeight: 'fit-content', overflow: 'hidden' }}
				>
					<Box className='payment_success_card'>
						<i class=' payment_icon fa-solid fa-check'></i>
						<Typography className='payment_title' component={'p'}>
							Payment Successfull
						</Typography>
					</Box>
				</DialogContent>
			</Dialog> */}

      <Dialog scroll={scroll} open={isCSVDialogOpen} className="dialogBox">
        <span
          onClick={() => setIsCSVDialogOpen(false)}
          class="material-symbols-outlined close_icon_csv"
        >
          cancel
        </span>
        <DialogContent
          sx={{ padding: "0px", minHeight: "fit-content", overflow: "hidden" }}
          className="csv-dialog-content"
        >
          <main className="main-upload-box">
            <form
              onClick={() => {
                uploadInputRef?.current?.click();
              }}
            >
              <input
                type="file"
                ref={uploadInputRef}
                accept=".csv"
                className="input-field"
                hidden
                onChange={handleFileChange}
              />

              <span className="material-icons-outlined upload-icon">
                {!csvFile ? "upload_file" : "check_circle"}
              </span>

              <p className="dark-color-text">
                {!csvFile ? "Browse Files to upload" : fileName}
              </p>
            </form>

            <Box style={{ display: "flex", marginTop: "13px" }}>
              <Button onClick={handleCSVUpload} className="upload-button">
                Upload
              </Button>
              <Button
                href={sampleCSV}
                download="sample.csv"
                className="upload-button"
              >
                <span
                  class="material-symbols-outlined"
                  style={{ marginRight: "5px" }}
                >
                  download
                </span>
                Sample CSV
              </Button>
            </Box>
          </main>
          {/* <form className='form-container'>
						<div className='upload-files-container'>
							<div className='drag-file-area'>
								<span className='material-icons-outlined upload-icon'>
									{!csvFile ? 'file_upload' : 'check_circle'}
								</span>
								<span className='browse-files'>
									<input type='file' hidden className='default-file-input' />
									<span className='browse-files-text'>Browse file</span>
								</span>
							</div>
							<div className='file-block'>
								<div className='file-info'>
									{' '}
									<span className='material-icons-outlined file-icon'>
										description
									</span>
									<span className='file-name'> </span> |{' '}
									<span className='file-size'> </span>{' '}
								</div>
								<span className='material-icons remove-file-icon'>delete</span>
								<div className='progress-bar'> </div>
							</div>
							<button type='button' className='upload-button'>
								{' '}
								Upload{' '}
							</button>
						</div>
					</form> */}
          {/* <form
						style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<TextField accept='.csv' onChange={handleFileChange} type='file' />
						<Box style={{ display: 'flex' }}>
							<Button
								onClick={handleCSVUpload}
								variant='contained'
								color='primary'
								component='span'
							>
								Upload
							</Button>
							<Button
								href={sampleCSV}
								variant='contained'
								download='sample.csv'
								className='sample-btn-csv'
								color='primary'
							>
								Download Sample CSV
							</Button>
						</Box>
					</form> */}
        </DialogContent>
      </Dialog>

      <Dialog scroll={scroll} open={isLoading} className="dialogBox">
        <DialogContent
          sx={{ padding: "0px", minHeight: "fit-content", overflow: "hidden" }}
        >
          <Box className="planCard premium">
            <Box
              className="planCard-type"
              style={{
                height: "100%",
                justifyContent: "start",
                marginTop: "35px",
                height: "100%",
              }}
            >
              <Box className="logo_wrapper">
                <img src={logo} />
              </Box>
              {isPartnerSuccessfullyCreated ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "60px",
                  }}
                >
                  <div
                    style={{
                      background: "#28b128",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      class=" payment_icon fa-solid fa-check"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      fontSize: "1.5em",
                      marginTop: "10px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Affiliate partner created succesfully.
                  </p>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box className="logo_div">
                    <div class="lds-dual-ring img"></div>
                  </Box>

                  <Typography
                    sx={{
                      marginTop: 3,
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    className="loading_text payment_title"
                    component={"p"}
                  >
                    Please wait while your Affiliate Agreement is being
                    prepared, along with your payment invoice, which you can
                    confirm in your registered email.
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "40px !important",
                      fontWeight: 600,
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    className="loading_text payment_title"
                    component={"p"}
                  >
                    PLEASE DO NOT REFRESH THE PAGE
                    <p
                      style={{
                        marginTop: "14px",
                        fontSize: "1.5em",
                        fontWeight: 600,
                      }}
                    >
                      00:{formatTime(seconds)}
                    </p>
                  </Typography>
                </Box>
              )}
            </Box>
            {isPartnerSuccessfullyCreated && (
              <Button
                className={"btn-ta filled"}
                onClick={() => setIsLoading(false)}
              >
                Done
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* terms and condition */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        className="term_condition_modal"
      >
        <DialogTitle>
          <Typography component={"h2"} className="modal_header">
            Affiliate Agreement With Terms & Condition
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText tabIndex={-1}>
            <Typography component={"h3"} style={{ fontWeight: "600" }}>
              NON-DISCLOSURE AGREEMENT WITH TERMS & CONDITION.
            </Typography>

            <Typography component={"p"}>
              This E-Commerce Affiliate Agreement ("Agreement") is entered into
              as of the date of acceptance through.{" "}
            </Typography>
            <Typography component={"p"}>
              {" "}
              digital acknowledgement {dayjs().format("DD-MM-YYYY HH:mm:ss A")},
              BY AND BETWEEN,
              <Typography component={"span"}>
                DeMatade Algo Technology Solutions Private Limited
              </Typography>
              , a company incorporated under the Companies Act, 1956 and having
              its registered office at Office No.311, 3rd floor, NMS Icon, Plot
              No. 194, Sector- 19, Ulwe Navi Mumbai 410206
            </Typography>
            <Typography component={"p"}>
              {" "}
              <Typography component={"span"}>
                AND AFFILIATE PARTY NAME
              </Typography>
            </Typography>
            <Typography component={"p"}>
              Affiliate Name:-{" "}
              {affiliateData?.firstname + " " + affiliateData?.lastname}
            </Typography>
            <Typography component={"p"}>
              Affiliate Contact:- {affiliateData?.mobile}
            </Typography>
            <Typography component={"p"}>
              Affiliate PAN No:- {affiliateData?.pan_number}
            </Typography>

            <Typography component={"p"}>
              a company incorporated under the Companies Act, 1956/2013 and
              having its registered office (hereinafter referred to as the
              “Receiving Party” which term shall unless repugnant to the context
              or meaning thereof be deemed to include its successors and
              permitted assigns)the Other Part.
            </Typography>
            <Typography component={"p"}>
              WHEREAS The Platform is operating an online marketplace accessible
              through the website (www.tradearth.in), or any other platforms or
              mobile applications TradeArth ("Platform/"Us"/"We") operated by
              DeMatade Algo Technology Solutions Private Limited{" "}
            </Typography>
            <Typography component={"p"}>
              WHEREAS The Platform is commonly known TradeArth{" "}
            </Typography>
            <Typography component={"p"}>
              AND WHEREAS the Affiliate has agreed to comply with the terms and
              conditions stipulated by the Platform for listing and selling
              their Products and Services, and the Platform has agreed to
              provide the Affiliate with the necessary digital infrastructure
              and services to facilitate the sale of their Products and Services
              to the end customers ("Customer").{" "}
            </Typography>
            <Typography component={"p"}>
              The Platform and Affiliate may be referred to individually as the
              "Party" and collectively as the "Parties".{" "}
            </Typography>
            <Typography component={"p"}>
              By clicking "I agree or by using the Platform to sell Products and
              Services, Affiliate acknowledge that Affiliate has read this
              Agreement, and agrees to be bound by its terms”.{" "}
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                1. AFFILIATE ELIGIBILITY
              </Typography>
            </Typography>

            <Typography component={"p"} className="subP">
              {" "}
              1.1. Access to and usage of the Platform is strictly limited to
              individuals or entities capable of forming legally binding
              contracts under applicable law. Parties deemed "Incompetent to
              contract, including but not limited to minors, un-discharged
              insolvents, or any other category specified by law, are not
              eligible to use the Platform.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              1.2. If you are a minor, that is, under the age of 18 (eighteen)
              years, you shall not register as a Affiliate on the Platform,
              engage in transactions, or use the Platform.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              1.3. The Provider reserves the right to terminate your
              registration and/or refuse you access to the Platform if it is
              brought to the notice or discovered that you are under the age of
              18 years.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              1.4. If the Affiliate is a business entity, it must be duly
              registered and in good standing under the laws of its country of
              incorporation or registration and have the requisite power and
              authority to conduct the business it is currently involved in.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              1.5. If You are registering as a business entity. You affirm that
              you have been duly authorized by the business entity to accept and
              enter into this Agreement and that you have the authority to bind
              the business entity to these terms.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                2. ACCOUNT AND REGISTRATION:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.1. In connection with your registration and use of the Platform,
              You agree to finish accurate, current, and complete details as may
              be required for Affiliate registration.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.2. This includes but is not limited to your full legal name,
              complete address, contact details GSTIN, PAN, Business PAN, Pin
              code, registered address of the business, pick-up address, bank
              account details, refer code name, pricing details of Products and
              Services and/or details.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.3. You affirm that all the information provided to the Platform
              is accurate, current, complete, and in accordance with this
              Agreement. If any information provided is found or suspected to be
              untrue, inaccurate, outdated, incomplete, or not in accordance
              with this Agreement, we reserve the right to indefinitely suspend,
              terminate or block access to your account on the Platform without
              prior notice.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.4. It is your responsibility to keep your account information up
              to date. You agree to promptly update all account information to
              keep it accurate, current, and complete. Any changes that may
              materially impact your transactions on the Platform should be
              immediately notified to us.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.5. The Service will be available to you subject to this
              Agreement and the applicable order(s), DeMatade Algo Technology
              Solutions Private Limited. will use commercially reasonable
              efforts to make the Service available working hours a day and
              working days a week, except during the planned downtime with
              advance notice to You or the occurrence of a Force Majeure Event.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.6. The Affiliate agrees that its use of the Platform is not
              contingent on the delivery of any future functionality or
              features, or dependent on any oral or written public comments by
              DeMatade Algo Technology Solutions Private Limited regarding
              future functionality or features
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.7. DeMatade Algo Technology Solutions Private Limited. reserves
              the right to modify the features and functionality of the Services
              during the term of the Agreement in case, if there is a
              modification of any functionality. DeMatade Algo Technology
              Solutions Private Limited. will provide an advance notice of
              7(Seven) working days to you
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.8. The provision and use of DeMatade Algo Technology Solutions
              Private Limited. in certain jurisdictions are subject to DeMatade
              Algo Technology Solutions Private Limited. Region Specific Terms.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.9. We might provide some or all elements of the Agreement
              Service through third-party service providers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              2.10, if you purchase any additional services or packages from us,
              this Agreement will apply to all additional orders and features
              that you activate within Your DeMatade Algo Technology Solutions
              Private Limited Account.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>3. ACCEPTABLE USE:</Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.1. You agree not to use the DeMatade Algo Technology Solutions
              Private Limited. or Services for any unlawful purpose or any
              purpose prohibited under this clause. You agree not to use the
              DeMatade Algo Technology Solutions Private Limited or Services in
              any way that could damage the DeMatade Algo Technology Solutions
              Private Limited, Services or general business of the Provider.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.2. The Affiliate can only list and sell the following Products
              and Services on this Platform:
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Subscription charges amount range between (1000 to 5000.) No other
              extra charges take from users
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.3. The Affiliate is restricted from listing or selling the
              following Products and Services
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                Crpytocurrencies
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                Forex Trading
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                Advisory Services
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                False Strategy performance
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                Fake profit promises
              </Typography>
            </Typography>

            <Typography component={"p"} className="subP">
              {" "}
              3.4. You further agree not to use the DeMatade Algo Technology
              Solutions Private Limited. or Services:
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.1. Engaging in any activity that could result in excessive
                bandwidth usage, degradation of performance, or disruption of
                the DeMatade Algo Technology Solutions Private Limited.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.2 to use for competitive benchmarking or for developing a
                competing Products and Services.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.3. To harass, abuse, or threaten others or otherwise violate
                any person's legal rights:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.4. To violate any intellectual property rights of the
                Provider or any third party:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.5. To upload or otherwise disseminate any computer viruses
                or other software that may damage the property of another,
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.6. To perpetrate any fraud:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.7. To engage in or create any unlawful gambling,
                sweepstakes, or pyramid scheme.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.8. To publish or distribute any obscene or defamatory
                material;
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3.4.9. To publish or distribute any statement that incites
                violence, hate or discrimination towards any group:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                3,4.10. To unlawfully gather information about others.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.5. You are prohibited from using the DeMatade Algo Technology
              Solutions Private Limited or its content: (a) for any unlawful
              purpose, (b) to solicit others to perform or participate in any
              unlawful acts (c) to infringe on any third party's intellectual
              property or proprietary rights, or rights of publicity or privacy,
              whether knowingly or solicit others to perform or participate in
              any unlawful acts; (c) to infringe on any third party's
              intellectual property or proprietary rights, or rights of
              publicity or privacy, whether knowingly or unknowingly, (d) to
              violate any local, federal or international law, statute,
              ordinance or regulation: (e) to harass, abuse, insult, harm,
              defame, slander, disparage, intimidate, or discriminate based on
              gender, sexual orientation, religion, ethnicity, race, age,
              national origin, or disability; (f) to submit false or misleading
              information or any content which is defamatory, libelous,
              threatening, unlawful, harassing, indecent, abusive, obscene, or
              lewd and lascivious, or exploits minors in any way or assists in
              human trafficking or content that would violate nights of
              publicity and/or privacy or that would violate any law. (g) to
              upload or transmit viruses or any other type of malicious code
              that will or may be used in any way that will affect the
              functionality or operation of the DeMatade Algo Technology
              Solutions Private Limited, other Products and Services, or the
              Internet; (h) to collect or track the personal Information of
              others: (1) to damage, disable, overburden, or impair the DeMatade
              Algo Technology Solutions Private Limited or any other party's use
              of the DeMatade Algo Technology Solutions Private Limited; () to
              spam, phish, pharm, pretext, spider, crawl, or scrape: (1) for any
              obscene or immoral purpose or (k) to interfere with or circumvent
              the security features of the DeMatade Algo Technology Solutions
              Private Limited, other Products and Services, or the Internet, ()
              to personally threaten or has the effect of personally threatening
              other Affiliates: We reserve the right to terminate Your use of
              DeMatade Algo Technology Solutions Private Limited for violating
              any of the prohibited uses.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.6. You acknowledge that the DeMatade Algo Technology Solutions
              Private Limited. is not responsible or liable and does not control
              the content of any information that may be posted or stored on the
              DeMatade Algo Technology Solutions Private Limited. by You or
              other users of the DeMatade Algo Technology Solutions Private
              Limited. and you are solely responsible for the same. You agree
              that You shall not upload, post, or transmit any content that you
              do not have a right to make available (such as the intellectual
              property of another party)
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.7. You agree to comply with all applicable laws, statutes and
              regulations concerning Your use of the DeMatade Algo Technology
              Solutions Private Limited. and further agree that You will not
              transmit any information, data, text, files, links, software,
              chats, communication or other materials that are abusive, invasive
              of another's privacy, harassing, defamatory, vulgar, obscene,
              unlawful, false, misleading, harmful, threatening, hateful or
              racially or otherwise objectionable, including without limitation
              material of any kind or nature that encourages conduct that could
              constitute a criminal offence, give rise to civil liability or
              otherwise violate any applicable local, state, provincial,
              national, or international law or regulation, or encourage the use
              of controlled substances.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.8. You may not use the Platform for any illegal or unauthorized
              purpose nor may you, in the use of the Service, violate any laws
              in your jurisdiction (including but not limited to copyright
              laws).
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.9. You acknowledge that the Service has not been designed to
              collect or process sensitive personal information and accordingly,
              you agree not to use the Service to collect process or store any
              sensitive information. We will not have, and we specifically
              disclaim any liability that may result from your use of the
              Service to collect, process and store sensitive information.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.10. You shall obtain all necessary consents and permissions from
              individuals whose personal data is collected or processed through
              the DeMatade Algo Technology Solutions Private Limited
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.11. The DeMatade Algo Technology Solutions Private Limited.
              reserves the right to monitor the Affiliate's use of DeMatade Algo
              Technology Solutions Private Limited. to ensure compliance with
              these terms and applicable laws.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              3.12. In the event of suspected violation of these terms,
              applicable laws, and regulations, we investigate and take
              appropriate actions, including but not limited to warning the
              Affiliate, suspending, or terminating the Affiliate's access to
              the DeMatade Algo Technology Solutions Private Limited., and
              reporting any illegal activities to the relevant authorities.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>4. SELLING:</Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.1, All commercial and contractual terms, which include but are
              not limited to price costs, payment methods and terms, periods,
              and modes, and Products and Services warranties and after-sale
              services, are exclusively offered by the Affiliate and agreed upon
              between the Affiliate and Customers. The Platform does not have
              any control, influence, or involvement in these commercial or
              contractual terms.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.2. The Platform does not make any representations or warranties
              regarding specifics, Products and Services proposed to be sold or
              purchased on the Platform. The Platform does not implicitly or
              explicitly endorse the sale or purchase of any Products and
              Services.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.3. The Platform does not accept any liability for any errors or
              omissions made by third parties in relation to the Products and
              Services offered on the Platform. It is also not responsible for
              any non- performance or breach of contract between the Affiliate
              or Customers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.4. The Platform cannot and does not guarantee that the Affiliate
              and Customers will perform any transactions concluded on the
              Platform and is not required to mediate or resolve any disputes or
              disagreements between the Affiliate and Customers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.5. The Platform is not hable for any inaccuracies, commissions,
              errors, or misrepresentations made by Affiliates, Customers, or
              third parties in relation to the Products and Services offered on
              the Platform. Additionally, the Platform holds no responsibility
              for any non-performance, delays, or breach of any contract agreed
              upon between the Affiliates and Customers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              4.6. At no point during a transaction does the Platform acquire,
              hold, or claim any ownership rights over the Products and Services
              sold by the Affiliate.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                5. CONTENT AND DESCRIPTION :
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              5.1. It is crucial that the items You list for sale do not violate
              any third-party intellectual property rights, trade secrets,
              proprietary rights, publicity rights, or privacy rights. You bear
              the responsibility for ensuring this compliance.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              5.2. Your listings should include accurate text descriptions,
              graphics pictures, or videos that genuinely represent that item
              for sale. Each item must be listed in an appropriate category on
              the Platform.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              5.3. The listed description must accurately reflect the actual
              details of the Products and Services.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              5.4. You are prohibited from listing a specific Products and
              Services in multiple quantities across various categories on the
              Platform. The Platform reserves the right to delete such multiple
              lists and may restrict the sale of Products and Services
              originating from certain countries or regions.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              5.5. If the same Products and Services are sold by multiple
              Affiliates through various or similar Products and Services page
              views, the Platform reserves the right to aggregate and present
              the best Products and Services data to enhance the Customer
              experience.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                6. PRICE AND INVENTORY:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              6.1. The Affiliate agrees and acknowledges that they are solely
              responsible for determining the price of the Products and Services
              listed on the Platform. The Affiliate must ensure that all pricing
              information is accurate and includes all applicable taxes,
              charges, and fees. The Affiliate shall also be responsible for
              promptly updating the prices of the Products and Services in the
              event of any changes.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              6.2. Affiliates are prohibited from artificially inflating prices
              or participating in price gouging practices.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              6.3. Affiliate may run promotional pricing or discount campaign
              only after obtaining prior approval from the Platform.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                7. REFUND AND RETURNS :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              for refund policies visit on privacy page.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                8. CUSTOMER REVIEWS AND RATINGS :{" "}
              </Typography>
            </Typography>

            <Typography component={"p"} className="subP">
              {" "}
              8.1. The Affiliate acknowledges that customers purchasing Products
              and Services from the Affiliate through the Platform may be asked
              to leave reviews and ratings regarding their purchase. These
              reviews and ratings may be made publicly available on the platform
              and can be viewed by all users of the Platform.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              8.2. The Affiliate acknowledges that they have no right to
              interfere with, manipulate or remote these reviews and ratings.
              Any attempt by the Affiliate to manipulate reviews or ratings will
              result in the immediate termination of this Agreement.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              8.3. Affiliates are not permitted to review their own Products and
              Services or the Products and Services of competitors. The Platform
              reserves the right to suspend or terminate the Affiliate's account
              if fraudulent or deceptive review practices are detected.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              8.4. Affiliate acknowledges that repeated low ratings or negative
              reviews may lead to account suspension or termination.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                9. AFFILIATE OBLIGATIONS :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.1. You must keep all your credentials including username and
              password highly confidential.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.2. You are responsible for maintaining the safety and security
              of your identifying information as well as keeping us apprised of
              any changes to your identifying information.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.3. In case, if your credentials are compromised. You agree to
              notify us immediately in writing.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.4. The billing information you provide us, including bank
              details, billing address and other payment information, is subject
              to the same confidentiality and accuracy requirements as the rest
              of your identifying information. Providing false or inaccurate
              information or using the DeMatade Algo Technology Solutions
              Private Limited or Services to further fraud or unlawful activity
              is grounds for immediate termination of Your Agreement and
              Account.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.5. Affiliate is responsible for compliance with the provisions
              of this Agreement by Agents and Customers and for any and all
              activities that occur under an Account, without foregoing.
              Affiliate will ensure that its use of the Services is in
              compliance with all applicable laws, regulations, privacy notices,
              Agreements, and other obligations with the Agents and Customers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.6. The Affiliate shall comply with all applicable data
              protection and privacy laws and regulations when processing and
              handling any personal data through the DeMatade Algo Technology
              Solutions Private Limited
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.7. The Affiliate agrees to cooperate with the DeMatade Algo
              Technology Solutions Private Limited in resolving any technical
              issues or Addressing any support requests related to the DeMatade
              Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.8. The Affiliate is responsible for ensuring full compliance
              with the provisions of IGST, CGST, UTGST, and/or SGST regarding
              the goods or services you provide.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              9.9. You must provide the corresponding Harmonized System
              Nomenclature (HSN) code for each Products and Services listing.
              Failure to provide an HSN code will result in Products and
              Services delisting and selling prohibition.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                10. AFFILIATE REGISTRATION:{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Affiliate Registration Charges: The registration fee is rs
              9,999/-, and it is non-refundable. GST is not included in this
              amount.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Affiliate Payout: Payouts will be released on the 5th of every
              following month.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>11. PAYMENT TERMS: </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.1. All transactions, inclusive of transaction prices, and any
              associated commercial terms such as subscription buy. The
              Platform's payment facility merely serves as an instrument
              utilized by Affiliates and Customers to ensure the successful
              execution of the transactions.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.2. The Platforms payment facility cannot be classified as a
              banking or financial service. It is an automated, electronic
              online payment mechanism developed for the purpose of receiving
              payment. facilitating payment, collection and remittance for
              transactions came out on the Platform.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.3. The Platform shall not be held accountable for instances of
              non- receipt, non- payment, damage, breach of representations and
              warranties, non-provision of after-sales or warranty services, or
              fraud regarding the Products and Services listed on the Platform
              as the Platform's payment facility does not provide a basis for
              such liability.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.4. The Platform does not provide assurance of the identity of
              any user nor does it offer a guarantee that a customer or a
              Affiliate will successfully complete a transaction
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.5. Payout Ratio: The monthly payout ratio depends on the number
              of active subscriptions:
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                1-10 active subscriptions: 60% to the affiliate and 40% to
                DeMatade Algo Technology Solutions Private Limited
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                11-20 active subscriptions: 70% to the affiliate and 30% to
                DeMatade Algo Technology Solutions Private Limited
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                21-30 active subscriptions: 80% to the affiliate and 20% to
                DeMatade Algo Technology Solutions Private Limited.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                Above 30 active subscriptions: 90% to the affiliate and 10% to
                DeMatade Algo Technology Solutions Private Limited.
              </Typography>
            </Typography>

            <Typography component={"p"} className="subP">
              {" "}
              11.6. Affiliates bear the sole responsibility for issuing invoices
              that are both correct and complete However, the Platform may offer
              assistance in this regard by generating invoices on behalf of the
              Affiliate.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.7. In the event that any chargebacks are lived by a bank, the
              Platform reserves the right to take action in accordance with
              appropriate guidelines and policies.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.8. Once the payment is received from the Customer, the Platform
              will deduct its commission or service fees as agreed upon, along
              with any applicable taxes, from the collected payment. The balance
              amount after such deductions will be remitted to the Affiliate.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.9. The Platform shall remit the due payment to the Affiliate on
              the pre- agreed frequency and the specific details of the
              remittance schedule will be communicated to the Affiliate in
              advance.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.10 in the event of any return, refund, or cancellation of
              orders by Customers, as per the Platform return and cancellation
              policy, the amount pertaining to such orders will be adjusted from
              the payments due to the Affiliate.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.11. The Platform will provide a detailed statement of account
              to the Affiliate reflecting sales, returns, Platform fees, and net
              amount payable to the Affiliate for each remittance cycle.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.12. The Platform reserves the right to withhold payment or
              deduct/adjust amounts from future payments in case of any
              violation of the Affiliate Agreement, policy violations, or any
              fraudulent activities conducted by the Affiliate.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.13. The Affiliate agrees to notify the Platform in writing
              within a reasonable time frame, not exceeding 7 (seven) calendar
              days, in case of any discrepancies or disputes regarding payments
              with the Platform or Customers.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.14. The payment to the Affiliate will be settled in the
              following manner
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.15. The Affiliate agrees to pay all applicable Service Fees and
              charges associated with their Agreement to DeMatade Algo
              Technology Solutions Private Limited as outlined in the service
              order, statement of work, supplemental terms, or otherwise agreed
              usage charges.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.16. The Affiliate authorizes the Provider to charge the
              designated payment method for all applicable Service Fees and
              charges. The Affiliate further authorizes DeMatade Algo Technology
              Solutions Private Limited to use a third party to process payments
              and consent to the disclosure of Your payment information to such
              third party.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.17. All Service Fees and charges are exclusive of any
              applicable taxes, including Goods and Services Tax (GST) or other
              similar taxes, imposed by the concerned government or authorities.
              The Affiliate shall be responsible for paying any such taxes as
              required by applicable laws.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.18. In addition to the Service Fee, the Provider may charge
              one-time setup fees, onboarding Fees or Fees for customization or
              integrations requested by the Affiliate. The details of such
              additional Service Fee shall be provided in writing by the
              Provider and agreed upon by both parties.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.19. The DeMatade Algo Technology Solutions Private Limited
              shall issue invoices or payment receipts to the Affiliate for all
              applicable Service Fees and charges, either electronically or
              through other agreed-upon means.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.20. The Affiliate shall review invoices promptly upon receipt
              and notify the DeMatade Algo Technology Solutions Private Limited
              of any discrepancies or concerns within a reasonable time frame.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.21. Unless otherwise stated in the pricing section or agreed
              upon by both parties in writing, all Service Fees and charges
              shall be payable in advance on a recurring basis.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.22. The payment is due within the specified timeframe from the
              invoice date or dates mentioned in the purchase order, and failure
              to make a timely payment may result in suspension or termination
              of the Affiliate's access to the DeMatade Algo Technology
              Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11:23. All Service Fees and charges are non-refundable, except as
              expressly provided in this Agreement or required by applicable
              law.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.24. The DeMatade Algo Technology Solutions Private Limited
              reserves the right to modify the Service Fee and charges for the
              Service upon proving the Affiliate with prior notice, which may be
              in the form of an updated pricing schedule of other written
              communication. The Service Fee adjustments shall become effective
              upon the start of the next billing cycle. In the event of a
              Service Fee increase, the Affiliate may have the option to
              terminate their Agreement upon written notice to the DeMatade Algo
              Technology Solutions Private Limited before the Effective Date of
              the Service Fee increase
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              11.25. The Platform reserves the right to deduct TDS or TCS as per
              the applicable laws
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                12. PLATFORM OBLIGATIONS:{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.1. The Platform will endeavour to use reasonable security
              measures to protect against unauthorized access to the platform
              and to Affiliate's data. This includes maintaining proper
              firewalls and protection systems
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.2. The Platform will provide reasonable technical support to
              the Affiliate as it relates to using the Platform for the sale of
              goods and/or services.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.3. The Platform agrees to provide the Affiliate with periodic
              sales reports showing the number of units of each Products and
              Services sold, total sales, and any returns or refunds processed
              during the reporting period.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.4. The Platform will provide basic customer service to the
              Customers, including assistance with navigating the Platform,
              making purchases, and addressing non-Affiliate-specific issues.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.5. The Platform will make reasonable efforts to limit downtime
              and ensure that Platform is available for use by Affiliates and
              Customers:
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              12.6. The Platform is responsible for ensuring that it complies
              with all applicable laws, regulations and standards in the
              operation of the e- commerce Platform.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                13. LEGAL COMPLIANCES :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              13.1. The Affiliate acknowledges and undertakes to comply with all
              applicable laws and agrees to sign the declarations detailed in
              this Agreement.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              13.2. If required under the law, the Affiliate must provide a
              hallmark certificate, and customer KYC according to anti-money
              laundering laws and other laws: The Platform disclaims any
              responsibility for conducting Customer KYC.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              13.3. The Affiliate ensures that no, providing services that
              originate wholly or partially from any country listed under any
              national or international sanctions.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              13.4. The Affiliate agrees to comply with all applicable data
              protection laws.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              13.5. The Affiliate warrants that it has not and will not engage
              in any form of bribery or corruption in (connection with its
              performance under this Agreement).
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                14. TERM, TERMINATION & SUSPENSION :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.1. The term of Service begins when the Affiliate sign-up for
              using the DeMatade Algo Technology Solutions Private Limited and
              will remain in effect as long as the Affiliate users the DeMatade
              Algo Technology Solutions Private Limited, or statement of work,
              or until this Agreement is otherwise terminated in accordance with
              the terms hereof, whichever occurs first.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.2. If You have selected for recurring Subscription scheme, upon
              the expiry of an existing plan, an amount equivalent to the
              Subscription Service Fee will be deducted automatically, if it
              fails, You shall make the payment within 7 (seven) calendar days
              from the due date, failing to do so, Your Agreement will be
              suspended or terminated.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.3. The Platform may terminate an Account by providing notice of
              15 (fifteen) calendar days to the Affiliate. To cancel the
              Agreement, the Affiliate can do the following.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.4. Upon receiving an account closure from You, the concerned
              account will be placed on hold for a duration of 15 (fifteen)
              calendar days. This pause ensures the completion of all prior
              transactions made before the closure request.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.5. During this 15 (fifteen) calendar days hold period, the
              Affiliate will have access to their account for the purpose of
              downloading payment and taxation reports as necessary.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.6. Once the hold period is over, the Affiliate is required to
              contact Us, confirming that they have downloaded the necessary
              reports and confirm the deactivation of the account.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.7. Upon receipt of the above confirmation and provided there
              are no outstanding payments due from the Affiliate, the
              Affiliate's account will be deactivated.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.8. Post-deactivation, certain information, including registered
              mobile number, GSTIN, email id. and other transaction-related
              information will be retained for audit purposes and as per the
              applicable laws.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.9. The Provider specifically reserves the right to terminate
              this Agreement if You violate any of the terms outlined herein,
              including, but not limited to, violating the intellectual property
              rights of the Provider or a third party, failing to comply with
              applicable laws or other legal obligations, and/or publishing or
              distributing illegal material.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.10. Either Party can terminate this Agreement if the other
              Party commits a material breach of these terms, other Agreements,
              or applicable laws not cured for more than 15 (fifteen) calendar
              days, if the other Party becomes subject to a petition in
              bankruptcy, or any other proceedings relating to insolvency,
              liquidation, or assignment for the benefit of creditors. In case
              the Affiliate terminates the Agreement, in accordance with this
              clause, the Affiliate will be entitled to a refund of any prepaid
              Service Fees covering the remainder of the Agreement term. In
              case, if the Agreement is terminated by the DeMatade Algo
              Technology Solutions Private Limited in accordance with this
              clause, the Affiliate must pay any unpaid Service Fees covering
              the remainder of the Agreement Term.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.11. At the termination of this Agreement, any provisions that
              would be expected to survive termination by their nature shall
              remain in full force and effect. You will be subject to this
              Agreement for as long as you have access to DeMatade Algo
              Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              14.12. In case of a paid Affiliate, upon Affiliate's written
              request, DeMatade Algo Technology Solutions Private Limited will
              make Service Data available to Affiliate for export or download
              for 7 (seven) calendar days after the Effective Date of
              termination, expiration or migration of the Account, except for
              Service Data deleted in accordance with these terms, or upon
              violation of applicable laws, or is against the law or legal
              order. Thereafter, the DeMatade Algo Technology Solutions Private
              Limited will have no obligation to retain the Service Data unless
              otherwise stated under applicable laws.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>15. COMMUNICATION : </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              15.1. In order to serve You better and communicate Service updates
              and security reasons, it is imminent to communicate with You
              constantly. Therefore, by using the Service. You consent to
              receive communications via phone or electronic records from the
              DeMatade Algo Technology Solutions Private Limited including
              e-mail messages telling You about products and services offered by
              the DeMatade Algo Technology Solutions Private Limited (or its
              affiliates and partners) and understanding Your requirements.
              Communication can also be by posting any notices on the DeMatade
              Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              15.2. You agree that the communications sent to You by the
              DeMatade Algo Technology Solutions Private Limited shall not be
              construed as spam or bulk under any law prevailing in any country
              where such communication is received.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              15.3. Each party shall promptly notify the other party in writing
              of any change in its contact information to ensure effective
              communication under this Agreement.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                16. PRIVACY INFORMATION
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              The Provider may collect and process personal information and
              other data from the Affiliate and its Agents, and Customers as
              necessary for the provision of providing the Service. The
              Provider's privacy policy shall govern the collection and
              processing of such data. The Affiliate is responsible for
              reviewing the privacy policy periodically to stay informed of any
              changes. You can access the privacy policy through the following
              link: https://tradearth.in/privacypolicy
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>17. ASSUMPTION OF RISK</Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              17.1. The Affiliate acknowledges that the use of DeMatade Algo
              Technology Solutions Private Limited comes certain inherent risks
              and Uncertainties including:
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                17.1.1. Technical risks such as interruptions, delays, or
                unavailability of DeMatade Algo Technology Solutions Private
                Limited due to hardware, software, or network failures
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                17.1.2. Compatibility risks such as incompatibility between
                DeMatade Algo Technology Solutions Private Limited and the
                Affiliate's Software, or network environment.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"p"} className="subP">
                {" "}
                17.1.3. Security narks, such as unauthorized access, data
                breaches, or loss of data.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              17.2. By using the DeMatade Algo Technology Solutions Private
              Limited, the Affiliate voluntarily accepts and assumes all risks
              associated with its use. The Affiliate acknowledges that the
              Provider shall not be held liable for any damages, losses, or harm
              arising from or related to the inherent risks of DeMatade Algo
              Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              17.3. The DeMatade Algo Technology Solutions Private Limited and
              Services are provided for communication purposes only. You
              acknowledge and agree that any information posted on DeMatade Algo
              Technology Solutions Private Limited is not intended to be legal
              advice, medical advice, or financial advice, and no fiduciary
              relationship has been created between.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              17.4. You and the Provider. You further agree that Your purchase
              of any of the Service on the DeMatade Algo Technology Solutions
              Private Limited is at your own risk. The Provider does not assume
              responsibility or liability for any advice or other information
              given on the DeMatade Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                18. INTELLECTUAL PROPERTY
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.1. You agree that the DeMatade Algo Technology Solutions
              Private Limited and all Services provided by the Provider are the
              property of the Provider, including all copyrights, trademarks
              trade secrets, patents, and other intellectual property ("Provider
              IP"). You agree that the Provider owns all nights, title, and
              interest in and to the Provider IP and that you will not use the
              Provider IP for any unlawful or infringing purpose. You agree not
              to reproduce or distribute the Provider IP in any way, including
              electronically or via registration of any new trademarks, trade
              names; service marks or Uniform Resource Locators (URLS), without
              express written permission from the Provider.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.2. You agree not to modify directly or indirectly, copy,
              reproduce, distribute, display, perform, or create derivative
              works based on the Provider IP.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.3. You acknowledge and agree that this Agreement does not give
              you any night to implement DeMatade Algo Technology Solutions
              Private Limited patents.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.4. Each Party shall retain all rights, titles and interests in
              its respective intellectual property rights. The rights granted to
              the Affiliate, Agents, and Customers to use the services under
              this Agreement do not include any additional rights or
              intellectual property rights of the DeMatade Algo Technology
              Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.5 If you come to know about any of Your intellectual property
              rights have been infringed or otherwise violated by the posting of
              information or media by another of Our Affiliates, please contact
              Us and let us know.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              18.6, In the event that the Affiliate becomes aware of any
              infringement or unauthorized use of the Provider IP the Affiliate
              shall promptly notify the Provider and provide all necessary
              assistance to protect the Provider's rights in the Provider.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                19. BENEFITS OF BECOMING AN AFFILIATE:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Sell your own product and services under your brand and logo.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Promote your franchise link and social media profiles.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Gain access to an admin dashboard for managing all your leads.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Access a user-friendly trading panel and mobile apps for both iOS
              and Android
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              Utilize the Strategy Builder to create customized trading
              strategies for your customers.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>20. INDEMNIFICATION : </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              20.1. DeMatade Algo Technology Solutions Private Limited will
              indemnify and defend the Affiliate from and against any claim
              brought by a third party against the Affiliate alleging
              Affiliate's use of Service infringes third-party valid
              intellectual property rights (IP Claim). DeMatade Algo Technology
              Solutions Private Limited shall, at DeMatade Algo Technology
              Solutions Private Limited's expense defend such IP claim and pay
              damages finally awarded against Affiliate in connection therewith,
              provided Affiliate promptly notify DeMatade Algo Technology
              Solutions Private Limited of a threat or notice of IP Claim,
              DeMatade Algo Technology Solutions Private Limited reserve the
              exclusive night to defend such claims, Affiliate full cooperates
              with DeMatade Algo Technology Solutions Private Limited in
              connection therewith.
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              20.2. Affiliate agrees to defend and indemnify the Provider and
              any of its affiliates (if applicable) and hold Us harmless against
              any and all legal claims and demands, including reasonable
              attorney's Fees, which may arise from or relate to Your use or
              misuse of the DeMatade Algo Technology Solutions Private Limited
              or Services, Your breach of this Agreement, or Your conduct or
              actions. DeMatade Algo Technology Solutions Private Limited will
              immediately notify the Affiliate of the threat or notice of such a
              claim. DeMatade Algo Technology Solutions Private Limited will
              cooperate with Affiliate in connection with such disputes. You
              agree that the Provider shall be able to select its own legal
              counsel and may participate in its own defense if the Provider
              wishes.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                21. THIRD-PARTY LINKS & CONTENT :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              {" "}
              21.1. The DeMatade Algo Technology Solutions Private Limited may
              contain links to third-party websites, apps, and resources. The
              Affiliate acknowledges and agrees that the Provider does not
              endorse or control such third-party websites. or resources, and
              the Provider shall not be responsible or liable for any content,
              advertising, products and services, or other materials available
              on such websites or resources.
            </Typography>
            <Typography component={"p"} className="subP">
              21.2. The Affiliate may choose to integrate third-party
              applications, plugins, or content (Third-Party Content") with the
              DeMatade Algo Technology Solutions Private Limited. Third-Party
              Content is subject to the terms and conditions of respective third
              parties and the Provider shall not be responsible or liable for
              any issues related to the use of Third-Party Content. Any concerns
              or disputes regarding the service, payment or any other matter
              concerning third-party websites or resources should be addressed
              directly to the applicable third party
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                22. MODIFICATION & VARIATION :
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              22.1. The Provider may, from time to time and at any time modify
              this Agreement. We will provide You with reasonable advance notice
              of changes to this Agreement that materially adversely affect Your
              use of the Service or Your rights through email or by notification
              or any other similar methods.
            </Typography>
            <Typography component={"p"} className="subP">
              22.2. If the Affiliate does not agree with any proposed
              modifications to this Agreement, the Vendor may terminate this
              Agreement in accordance with the Term and Termination clause
              herein.
            </Typography>
            <Typography component={"p"} className="subP">
              22.3. To the extent any part or sub-part of this Agreement is held
              ineffective or invalid by any court of law, You agree that the
              prior, effective version of this Agreement shall be considered
              enforceable and valid to the fullest extent.
            </Typography>
            <Typography component={"p"} className="subP">
              22 4. You agree to routinely monitor this Agreement and refer to
              the Effective Data posted at the top of this Agreement to note
              modifications or variations. You further agree to clear Your cache
              when doing so to avoid accessing a prior version of this
              Agreement. You agree that Your continued use of the DeMatade Algo
              Technology Solutions Private Limited after any modifications to
              this Agreement is a manifestation of Your continued assent to this
              Agreement.
            </Typography>
            <Typography component={"p"} className="subP">
              22.5. In the event that You fail to monitor any modifications to
              or variations of this Agreement. You agree that such failure shall
              be considered an affirmative waiver of Your right to review the
              modified Agreement.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                23. ENTIRE AGREEMENT :{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              This Agreement constitutes the entire understanding between the
              Parties with respect to any and all use of this DeMatade Algo
              Technology Solutions Private Limited. This Agreement supersedes
              and replaces all prior or contemporaneous Agreements or
              understandings, written or oral, regarding the use of this
              DeMatade Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>24. NO WARRANTIES : </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              24.1. You agree that Your use of the DeMatade Algo Technology
              Solutions Private Limited and Services is at your sole and
              exclusive risk and that any Services provided by Us are on an 'as
              is' basis. The Provider hereby expressly disclaims any and all
              express or implied warranties of any kind, including, but not
              limited to the implied warranty of fitness for a particular
              purpose and the implied warranty of merchantability.
            </Typography>
            <Typography component={"p"} className="subP">
              24.2. The Provider makes no warranties that the DeMatade Algo
              Technology Solutions Private Limited or Services will meet Your
              needs or that the DeMatade Algo Technology Solutions Private
              Limited or Services will be uninterrupted, error-free, or secure.
              The Provider also makes no warranties as to the reliability or
              accuracy of any information on the DeMatade Algo Technology
              Solutions Private Limited or obtained through the Services.
            </Typography>
            <Typography component={"p"} className="subP">
              24.3. You agree that any damage that may occur to You, through
              Your computer system, or as a result of the loss of Your data from
              Your use of the DeMatade Algo Technology Solutions Private Limited
              of Services is Your sole responsibility. and that the Provider is
              not liable for any such damage or loss.
            </Typography>
            <Typography component={"p"} className="subP">
              24.4. All information, software, products, services, and related
              graphics are provided on this DeMatade Algo Technology Solutions
              Private Limited is "as is" and "as available" basis without
              warranty of any kind, either expressed or implied.
            </Typography>
            <Typography component={"p"} className="subP">
              24.5. The DeMatade Algo Technology Solutions Private Limited
              disclaims all warranties, expressed, or implied including without
              limitation, all implied warranties of merchantability, and fitness
              for a particular purpose, title and non-infringement or arising
              from a course of dealing usage, or trade practice.
            </Typography>
            <Typography component={"p"} className="subP">
              24.6, The Provider makes no representation about the suitability
              of the information, tools, add-ons, etc. contained on this
              DeMatade Algo Technology Solutions Private Limited for any
              purpose, and the inclusion or offering of any services on this
              DeMatade Algo Technology Solutions Private Limited does not
              constitute any endorsement or recommendation of such Products and
              Services
            </Typography>
            <Typography component={"p"} className="subP">
              24.7. The DeMatade Algo Technology Solutions Private Limited makes
              no warranty that the use of the DeMatade Algo Technology Solutions
              Private Limited will be uninterrupted, timely. secure, without
              defect or error-free. You expressly agree that use of the DeMatade
              Algo Technology Solutions Private Limited is at Your own risk. The
              DeMatade Algo Technology Solutions Private Limited shall not be
              responsible for any content found on the DeMatade Algo Technology
              Solutions Private Limited
            </Typography>
            <Typography component={"p"} className="subP">
              24.8. Your use of any information or materials on this DeMatade
              Algo Technology Solutions Private Limited or otherwise obtained
              through the use of this DeMatade Algo Technology Solutions Private
              Limited is entirely at Your own risk, for which we shall not be
              liable. It shall be Your own responsibility to ensure that any
              services or information available through this DeMatade Algo
              Technology Solutions Private Limited meet Your specific
              requirements.
            </Typography>
            <Typography component={"p"} className="subP">
              24.9. The DeMatade Algo Technology Solutions Private Limited
              assumes no responsibility for the accuracy, currency, completeness
              or usefulness of information, views, opinions, or advice in any
              material contained in the DeMatade Algo Technology Solutions
              Private Limited. Any information from third parties or advertisers
              is made available without making any changes and so the DeMatade
              Algo Technology Solutions Private Limited cannot guarantee
              accuracy and is not liable for any inconsistencies arising
              thereof.
            </Typography>
            <Typography component={"p"} className="subP">
              24.10. All postings, messages, advertisements, photos, sounds,
              images, text, files, video, or other materials posted on,
              transmitted through, or linked from the DeMatade Algo Technology
              Solutions Private Limited, are solely the responsibility of the
              person from whom such Content-originated, and the DeMatade Algo
              Technology Solutions Private Limited does not control and is not
              responsible for Content available on the DeMatade Algo Technology
              Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              24.11. There may be instances when incorrect information is
              published inadvertently on Our DeMatade Algo Technology Solutions
              Private Limited or in the Service such as typographical errors,
              inaccuracies or omissions that may relate to Products and Services
              descriptions, pricing, promotions, offers, and availability: Any
              errors, inaccuracies, or omissions may be corrected at Our
              discretion at any time, and we may change or update information or
              cancel orders if any information in the Service or on any related
              DeMatade Algo Technology Solutions Private Limited is inaccurate
              at any time without prior notice (including after You have
              submitted Your order).
            </Typography>
            <Typography component={"p"} className="subP">
              24.12. We undertake no obligation to update amend or clarify
              information in the Service or on any related DeMatade Algo
              Technology Solutions Private Limited, including without
              limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Service or on any
              related DeMatade Algo Technology Solutions Private Limited should
              be taken to indicate that all information in the Service or on any
              related DeMatade Algo Technology Solutions Private Limited has
              been modified or updated.
            </Typography>
            <Typography component={"p"} className="subP">
              24.13. The DeMatade Algo Technology Solutions Private Limited
              shall not be responsible for any interaction between You and the
              other Affiliates of the DeMatade Algo Technology Solutions Private
              Limited. Under no circumstances will the DeMatade Algo Technology
              Solutions Private Limited be liable for any goods, services,
              resources, or content available through such third- party dealings
              or communications, of for any harm related thereto. The DeMatade
              Algo Technology Solutions Private Limited is under no obligation
              to become involved in any disputes between You and other
              Affiliates of the DeMatade Algo Technology Solutions Private
              Limited or between You and any other third parties. You agree to
              release the DeMatade Algo Technology Solutions Private Limited
              from any and all claims, demands, and damages arising out of or in
              connection with such dispute.
            </Typography>
            <Typography component={"p"} className="subP">
              24.14. You agree and understand that while the DeMatade Algo
              Technology Solutions Private Limited has made reasonable efforts
              to safeguard the DeMatade Algo Technology Solutions Private
              Limited, it cannot and does not ensure or make any representations
              that the DeMatade Algo Technology Solutions Private Limited or any
              of the information provided by You cannot be hacked by any
              unauthorized third parties. You specifically agree that the
              DeMatade Algo Technology Solutions Private Limited shall not be
              responsible for unauthorized access to or alteration of Your
              transmissions or data, any material or data sent or received or
              not sent or received, or any transactions entered into through the
              DeMatade Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              24.15. You hereby agree and confirm that the DeMatade Algo
              Technology Solutions Private Limited shall not be held liable or
              responsible in any manner whatsoever for such hacking or any loss
              or damages suffered by You due to unauthorized access of the
              DeMatade Algo Technology Solutions Private Limited by third
              parties or for any such use of the information provided by You or
              any spam messages or information that You may receive from any
              such unauthorized third party (including those which are although
              sent representing the name of the DeMatade Algo Technology
              Solutions Private Limited but have not been authorized by the
              DeMatade Algo Technology Solutions Private Limited) which is in
              violation or contravention of this Agreement or the Privacy
              Policy.
            </Typography>
            <Typography component={"p"} className="subP">
              24:16. you specifically agree that the DeMatade Algo Technology
              Solutions Private Limited is not responsible or liable for any
              threatening. Defamatory, obscene, offensive, or illegal content or
              conduct of any other party or any infringement of another's
              rights, including intellectual property rights. You specifically
              agree that the DeMatade Algo Technology Solutions Private Limited
              is not responsible for any content sent using and/or included on
              the DeMatade Algo Technology Solutions Private Limited by any
              third party.
            </Typography>
            <Typography component={"p"} className="subP">
              24.17. The DeMatade Algo Technology Solutions Private Limited has
              no liability and will make no refund in the event of any delay,
              cancellation, strike, force majeure, or other causes beyond their
              direct control, and they have no responsibility for any additional
              expense omissions delays or acts of any government or authority.
            </Typography>
            <Typography component={"p"} className="subP">
              24.18. You will be solely responsible for any damage to Your
              computer system or loss of data that results from the download of
              any information and/or material. Some jurisdictions do not allow
              the exclusion of certain warranties, so some of the above
              exclusions may not apply to you.
            </Typography>
            <Typography component={"p"} className="subP">
              24.19. You accept all responsibility for and hereby agree to
              indemnify and hold harmless the Provider from and against, any
              actions taken by You or by any person authorized to use Your
              Account, including without limitation disclosure of passwords to
              third parties. If You are dissatisfied with the DeMatade Algo
              Technology Solutions Private Limited, or the Services or any
              portion thereof, or do not agree with these terms, Your only
              recourse and exclusive remedy shall be to stop using the DeMatade
              Algo Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                25. LIMITATION OF LIABILITY{" "}
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              25.1. In no event shall the DeMatade Algo Technology Solutions
              Private Limited. be liable for any direct, indirect, punitive,
              incidental, special, consequential damages or any damages
              whatsoever including, without limitation, damages for loss of use,
              data or profits, arising out of or in any way connected with the
              use or performance of the DeMatade Algo Technology Solutions
              Private Limited, with the delay or inability to use the DeMatade
              Algo Technology Solutions Private Limited or related services, the
              provision of or failure to provide Services, or to deliver the
              Products and Services or for any information, software, products,
              services and related graphics obtained through the DeMatade Algo
              Technology Solutions Private Limited, or any interaction between
              You and other participants of the DeMatade Algo Technology
              Solutions Private Limited or otherwise arising out of the use of
              the DeMatade Algo Technology Solutions Private Limited, damages
              resulting from use of or reliance on the information present,
              whether based on contract, tort negligence, strict liability or
              otherwise, even if the DeMatade Algo Technology Solutions Private
              Limited or any of its affiliates/suppliers has been advised of the
              possibility of damages. If despite the limitation above, the
              Company is found liable for any loss or damage which arises out of
              or in any way connected with the use of the DeMatade Algo
              Technology Solutions Private Limited and/or provision of Services,
              then the liability of the Company will be zero.
            </Typography>
            <Typography component={"p"} className="subP">
              25.2. This aforementioned limitation applies to any and all claims
              by you, including, but not limited to, lost profits or revenues,
              consequential or punitive damages, negligence, strict liability,
              fraud, or torts of any kind.
            </Typography>
            <Typography component={"p"}>
              <Typography component={"span"}>
                26. GENERAL PROVISIONS:
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              26.1. AUTHORITY: Each party represents and warrants to the other
              that it has full power and authority to enter into this Agreement
              and that is binding upon such party and enforceable in accordance
              with its terms. You further warrant and represent that You have
              the authority to procure Your Affiliate's compliance with the
              terms of this Agreement.
            </Typography>
            <Typography component={"p"} className="subP">
              26.2. LANGUAGE: This Agreement and all related communications,
              notices and documentation shall be conducted in the English
              language. Any translations provided for convenience of
              informational purposes are not guaranteed to be accurate or
              complete, and the English version of this Agreement shall prevail
              in case of any discrepancies or conflicts.
            </Typography>
            <Typography component={"p"} className="subP">
              26.3. JURISDICTION, VENUE & GOVERNING LAW: Through Your use of the
              DeMatade Algo Technology Solutions Private Limited or Services,
              You agree that the laws of India shall govern any matter or
              dispute relating to or an using out of this Agreement, as well as
              any dispute of any kind that may arise between You and the
              Provider, with the exception of its conflict of law provisions.
              Both the Parties do hereby agree that any dispute arising out of
              or in relation to this Agreement shall be settled in accordance
              with the provisions of the Arbitration and Conciliation Act, 1996
              and/or any statutory modification or re-enactment thereof for the
              time being in force.The Parties shall mutually appoint a single
              Arbitrator Each Party shall pay their own costs and Fees of the
              arbitration and the cost of the arbitrator shall be bore equally.
              The seat or place of the arbitration shall be as follows:
              Maharashtra. The language of the arbitration shall be: English,
              Hindi The Agreement shall be governed in accordance with the laws
              of India and the courts of Maharashtra will have the exclusive
              jurisdiction.
            </Typography>
            <Typography component={"p"} className="subP">
              26.4. ASSIGNMENT: The Affiliate shall not assign, transfer, or
              delegate any rights or obligations under this Agreement without
              the prior written consent of the Provider. However, the Provider
              may assign or transfer this Agreement, in whole or in part, to any
              affiliated entity or in connection with a merger, acquisition, or
              sale of assets. Any attempted assignment in violation of this
              clause shall be null and void. This Agreement shall be binding
              upon and inure to the benefit of the parties and their respective
              successors and permitted assigns.
            </Typography>
            <Typography component={"p"} className="subP">
              26.5. SEVERABILITY: If any part or sub part of this Agreement is
              held invalid or unenforceable by a court of law or competent
              arbitrator, the remaining parts and sub-parts will be enforced to
              the maximum extent possible. The parties agree to replace the
              severed provision with a valid and enforceable provision that
              reflects the original intent of the Agreement to the maximum
              extent possible.
            </Typography>
            <Typography component={"p"} className="subP">
              26.6. NO WAIVER: The failure of either party to enforce any right
              or provision of this Agreement shall not be deemed a waiver of
              such right or provision. The waiver of any breach of this
              Agreement shall not constitute a waiver of any subsequent breach.
              No waiver shall be effective unless it is expressly stated in
              writing and signed by the waiving party.
            </Typography>
            <Typography component={"p"} className="subP">
              26.7. HEADINGS FOR CONVENIENCE ONLY: Headings of parts and
              sub-parts under this Agreement are for convenience and
              organization, only. Headings shall not affect the meaning of any
              provisions of this Agreement.
            </Typography>
            <Typography component={"p"} className="subP">
              26.8.NO AGENCY, PARTNERSHIP OR JOINT VENTURE: Nothing in this
              Agreement shall be construed as creating an agency, partnership,
              joint venture, or any other form of legal association between the
              Affiliate and the Provider. This Agreement does not authorize
              either party to act as an Agent or representative of the other
              party
            </Typography>
            <Typography component={"p"} className="subP">
              26.9. FORCE MAJEURE: Neither party shall be liable for any failure
              or delay in the performance of its obligations under this
              Agreement if such failure or delay is caused by events beyond its
              reasonable control, including but not limited to acts of God,
              natural disasters, fires, floods, epidemics, pandemics, war,
              terrorism, strikes, government actions, or any other event that is
              unforeseeable and beyond the reasonable control of the affected
              party ("Force Majeure Event"). The party affected by the Force
              Majeure Event shall promptly notify the other party in writing of
              the occurrence and anticipated duration of such event. The
              affected party's performance under this Agreement shall be
              suspended during the Force Majeure Event, and the time for
              performance shall be extended for a period equal to the duration
              of the Force Majeure Event. If the Force Majeure Event continues
              for a period of 15 (fifteen) calendar days, either party may
              terminate this Agreement by providing written notice to the other
              party without incurring any liability for such termination.
            </Typography>
            <Typography component={"p"} className="subP">
              26.10. ELECTRONIC COMMUNICATIONS PERMITTED: The parties agree that
              electronic communications, including but not limited to email,
              electronic signatures, and online messaging, shall be deemed valid
              and legally binding for all purposes under this Agreement. Such
              electronic communication shall have the same force and effect as
              if they were in writing and signed by the parties. The parties
              further acknowledge that electronic communications are reliable,
              confidential and secure means of communication.
            </Typography>
            <Typography component={"p"} className="subP">
              You can contact DeMatade Algo Technology Solutions Private
              Limited.
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"span"} className="subP">
                contact@tradearth.in
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"span"} className="subP">
                Disclaimer:
              </Typography>{" "}
              DeMatade Algo Technology Solutions Private Limited. is not
              responsible for any trading profit or loss incurred by affiliates
              or their customers. Prospective affiliates are advised to read all
              related documents carefully before joining. affiliates are also
              urged not to make any false promises or statements to users
              regarding the system and strategies.
            </Typography>
            <Typography component={"p"} className="subP">
              It's important for interested individuals to carefully consider
              the terms and conditions before becoming a affiliate. The pay-out
              structure seems to reward affiliates based on their performance,
              which may provide an incentive to grow their subscriber base.
              However, as with any business opportunity, potential affiliates
              should exercise due diligence and understand the risks involved in
              Algo trading and managing customer expectations.
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"span"}>
                For DeMatade Algo Technology Solutions Private Limited.
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              <Typography component={"span"}>
                Mr. 'Pawan Matade' – Founder
              </Typography>
            </Typography>
            <img
              className="signature"
              style={{ height: 150, width: 200 }}
              src={digital_signature}
            />
            <Typography component={"p"} className="subP">
              <Typography component={"span"}>
                For Register Affiliate:-
              </Typography>
            </Typography>
            <Typography component={"p"} className="subP">
              Name:-
              {affiliateData?.firstname + " " + affiliateData?.lastname}
            </Typography>
            <Typography component={"p"} className="subP">
              Signature: - _____________________________________
            </Typography>
            <Typography component={"p"} className="subP">
              Date :- {dayjs().format("DD-MM-YYYY HH:mm:ss A")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="btn btn-ta outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setCommitment(true);
            }}
            className="btn btn-ta filled"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={openotp} scroll={scroll} className='OTP_verify_modal'>
				<DialogTitle>
					<Typography component={'h2'} className='modal_header'>
						Mobile Number Verification
					</Typography>
				</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText tabIndex={-1}>
						<Box className='modal_body'>
							<Typography component={'h4'}>OTP send to +91{affiliateData?.mobile}</Typography>
							<Typography component={'p'}>
								Enter the OTP You Received on Your Mobile Number
							</Typography>

							<Box className='otp_inputs'>
								<div className='otp_boxex'>
									{otp.map((digit, index) => {
										return (
											<Box className='form_control'>
												<input
													className='input_otp'
													type='text'
													inputMode='numeric' // Set inputMode to "numeric"
													pattern='[0-9]*'
													key={index}
													value={digit}
													onKeyDown={(e) => {
														handleOtpChange(e, index);
													}}
													onPaste={(event) => {
														const otp = event.clipboardData.getData('text');

														if (otp.length === 6) {
															let newOtp = [];
															for (let i = 0; i < otp.length; i++) {
																if (Number(otp[i])) {
																	newOtp.push(otp[i]);
																} else {
																	break;
																}
															}
															if (newOtp.length !== 0) {
																setOTP(newOtp);
																inputRefs[otp.length - 1]?.current?.focus();
															}
														}
													}}
													maxLength={1}
													ref={inputRefs[index]}
												/>
											</Box>
										);
									})}
								</div>
							</Box>
							<Box>
								{readOnly && (
									<Typography component={'p'} className='valide_otp'>
										You can Resend the OTP after
										<Typography component={'span'} style={{ color: 'red' }}>
											{' '}
											{' ' + count + ' '}
										</Typography>
										Seconds.
									</Typography>
								)}
							</Box>
						</Box>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={!readOnly ? 'btn btn-ta filled' : 'btn btn-ta disabled'}
						disabled={readOnly}
						onClick={sendOTP}
					>
						Resend Otp
					</Button>
					<Button onClick={handleCloseotp} className='btn btn-ta outlined'>
						Cancel
					</Button>
					<Button
						onClick={sendAffiliateData}
						className={
							fullOTP?.length === 6
								? 'btn btn-ta filled'
								: 'btn btn-ta disabled'
						}
						disabled={!isLoading && fullOTP?.length === 6 ? false : true}
					>
						{!isLoading ? 'Submit' : 'Loading...'}
					</Button>
				</DialogActions>
			</Dialog> */}
    </>
  );
}
