import { ConstructionOutlined } from '@mui/icons-material';

export const validateValues = (mobile) => {
	const isValid = /^\d{10}$/.test(mobile);
	if (mobile === '') {
		return 'Mobile Number is a required field';
	} else if (!isValid) {
		return 'Mobile number should be 10 digits only';
	} else if (mobile === '1234567890') {
		return 'Please enter a valid mobile number';
	} else {
		const firstDigit = mobile[0];
		for (let i = 1; i < mobile.length; i++) {
			if (mobile[i] !== firstDigit) {
				return true; // Valid if any digit is different
			}
		}
		return 'Please enter a valid mobile number';
	}
};

export const validateOtp = (otp) => {
	let isValid = {};
	if (otp === '') {
		return (isValid.otp = 'Please enter valid otp');
	}
};

export const validateRegister = (fields, isSubmit = false) => {
  console.log("fields logs777", fields);
  let isValid = {};

  if (fields?.firstname) {
    if (!/^[A-Za-z]+$/.test(fields?.firstname)) {
      isValid.firstname = "First name should only contain letters";
    } else if (
      fields?.firstname?.length < 3 ||
      fields?.firstname?.length > 10
    ) {
      isValid.firstname = "First name should be of 3-10 characters";
    }
  }

  if (fields?.lastname) {
    if (!/^[A-Za-z]+$/.test(fields?.lastname)) {
      isValid.lastname = "Last name should only contain letters";
    } else if (fields?.lastname?.length < 3 || fields?.lastname?.length > 10) {
      isValid.lastname = "Last name should be of 3-10 characters";
    }
  }

  if (fields?.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields?.email)) {
      isValid.email = "Please enter valid email address";
    } else if (
      !(fields?.email?.length >= 15) ||
      !(fields?.email?.length <= 40)
    ) {
      isValid.email = "Email length should be of 15 to 40 characters";
    } else if (
      !fields?.email?.endsWith(".com") &&
      !fields?.email?.endsWith(".in")
    ) {
      isValid.email = "Please enter valid email address";
    }
  }

  if (fields?.otp) {
    if (fields?.otp?.length < 6) {
      isValid.otp = "OTP length should be of 6 digits";
    }
  }

  // if (fields?.refrecode) {
  // 	if (fields?.refrecode?.length < 5 || fields?.refrecode?.length > 10) {
  // 		isValid.refrecode = 'Promocode should be of 5 to 10 characters';
  // 	} else if (!/^[A-Za-z0-9]+$/.test(fields?.refrecode)) {
  // 		isValid.refrecode = 'Please enter valid promo code';
  // 	}
  // }

  // if (!fields.DOB) {
  //   isValid.DOB = "Please enter birth date";
  // }
  //   else {
  //     const dobRegex =
  //       /^(0[1-9]|[12][0-9]|3[01])\/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\/\d{4}$/i;
  //     if (!dobRegex.test(fields.DOB)) {
  //       isValid.DOB = "Invalid date of birth format (dd/Mon/yyyy)";
  //     }
  //   }
  console.log("isValid", isValid);
  return isValid;
};

export const validateSubmit = (fields) => {
  console.log("fields", fields);
  let isValid = {};

  if (!fields?.firstname) {
    isValid.firstname = "Please enter first name";
  }

  if (!fields?.lastname) {
    isValid.lastname = "Please enter last name";
  }

  if (!fields?.email) {
    isValid.email = "Please enter email address";
  }

  if (!fields?.otp) {
    isValid.otp = "Please enter OTP";
  }

  // if (!fields?.refrecode) {
  // 	isValid.refrecode = 'Please enter promo code';
  // }

  // if (!fields.DOB) {
  //   isValid.DOB = "Please enter birth date";
  // }
  //   else {
  //     const dobRegex =
  //       /^(0[1-9]|[12][0-9]|3[01])\/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\/\d{4}$/i;
  //     if (!dobRegex.test(fields.DOB)) {
  //       isValid.DOB = "Invalid date of birth format (dd/Mon/yyyy)";
  //     }
  //   }
  console.log("isValid", isValid);
  return isValid;
};
