import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import logo from '../images/logo.png';
import platstore from '../images/playstore.png';
import appstore from '../images/appstore.png';
import tele from '../images/telegram.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import whatsapp from '../images/whatsapp.png';
import insta from '../images/instagram.png';
import twitter from '../images/twitter.png';
import fl from '../images/footer-line.png';

import { NavLink } from 'react-router-dom';

export default function Footer() {
	return (
    <>
      <Box component={"footer"} className="footer">
        <Box className="container">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography component={"a"} className="logo_footer">
                <img src={logo} height="100%" width="100%" />
              </Typography>
              <Typography component={"p"} className="desc_footer">
                Welcome to the world of algorithmic trading – where innovation
                meets financial markets. At TradeArth, we believe that the
                future of trading lies in the power of algorithms.Algorithmic
                trading, often referred to as algo trading, is a cutting-edge
                approach that leverages advanced mathematical models and
                automated systems to execute trades with speed, precision, and
                efficiency.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={5}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "0 1rem",
                    width: { xs: "100%", md: "40%", lg: "35%" },
                  }}
                >
                  <Typography component={"h3"} className="footer_mt">
                    Explore
                  </Typography>
                  <Box component={"ul"} className="footer_menu">
                    <Box component={"li"}>
                      <NavLink component={"a"} to={"/ "} className="nav_link">
                        Home
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink
                        component={"a"}
                        to={"/about"}
                        className="nav_link"
                      >
                        About us
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink
                        component={"a"}
                        to={"/affiliate"}
                        className="nav_link"
                      >
                        Affiliate
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink component={"a"} to={"/faq"} className="nav_link">
                        FAQ
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink
                        component={"a"}
                        to={"/privacypolicy"}
                        className="nav_link"
                      >
                        Privacy Policy
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink
                        component={"a"}
                        to={"/descliamer"}
                        className="nav_link"
                      >
                        Disclaimer
                      </NavLink>
                    </Box>
                    <Box component={"li"}>
                      <NavLink
                        component={"a"}
                        to={"/contact"}
                        className="nav_link"
                      >
                        Contact
                      </NavLink>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "0 1rem",
                    width: { xs: "100%", md: "60%", lg: "65%" },
                  }}
                >
                  <Typography component={"h3"} className="footer_mt">
                    Contact us
                  </Typography>
                  <Box component={"ul"} className="footer_menu">
                    <Box
                      component={"li"}
                      sx={{ display: "flex" }}
                      className="mrbin"
                    >
                      <Typography component={"span"}>
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M20.62 9.65214C19.57 4.57256 15.54 2.28564 12 2.28564C12 2.28564 12 2.28564 11.99 2.28564C8.46 2.28564 4.42 4.56156 3.37 9.64114C2.2 15.3144 5.36 20.1192 8.22 23.1427C9.28 24.2642 10.64 24.8249 12 24.8249C13.36 24.8249 14.72 24.2642 15.77 23.1427C18.63 20.1192 21.79 15.3254 20.62 9.65214Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M12 15.1601C13.7397 15.1601 15.15 13.6095 15.15 11.6967C15.15 9.78399 13.7397 8.2334 12 8.2334C10.2603 8.2334 8.85001 9.78399 8.85001 11.6967C8.85001 13.6095 10.2603 15.1601 12 15.1601Z"
                            fill="#0A53B5"
                          />
                        </svg>
                      </Typography>
                      <Typography component={"p"} className="nav_link">
                        Office No.311, 3rd floor, NMS Icon, Plot No. 194,
                        Sector- 19, Ulwe Navi Mumbai 410206
                      </Typography>
                    </Box>
                    <Box
                      component={"li"}
                      className="nomb"
                      sx={{ display: "flex" }}
                    >
                      <Typography component={"span"}>
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.62 12.1442C17.19 12.1442 16.85 11.7594 16.85 11.2976C16.85 10.8908 16.48 10.0442 15.86 9.30759C15.25 8.59293 14.58 8.17513 14.02 8.17513C13.59 8.17513 13.25 7.79031 13.25 7.32853C13.25 6.86675 13.6 6.48193 14.02 6.48193C15.02 6.48193 16.07 7.07565 16.99 8.14214C17.85 9.14267 18.4 10.3851 18.4 11.2866C18.4 11.7594 18.05 12.1442 17.62 12.1442Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.23 12.1439C20.8 12.1439 20.46 11.759 20.46 11.2973C20.46 7.39412 17.57 4.22763 14.03 4.22763C13.6 4.22763 13.26 3.84281 13.26 3.38103C13.26 2.91925 13.6 2.52344 14.02 2.52344C18.42 2.52344 22 6.45956 22 11.2973C22 11.759 21.65 12.1439 21.23 12.1439Z"
                            fill="#0A53B5"
                          />
                          <path
                            opacity="0.4"
                            d="M11.79 15.948L8.52 19.5433C8.16 19.1915 7.81 18.8287 7.47 18.4548C6.44 17.3114 5.51 16.113 4.68 14.8596C3.86 13.6062 3.2 12.3528 2.72 11.1103C2.24 9.85695 2 8.65852 2 7.51506C2 6.76742 2.12 6.05276 2.36 5.39307C2.6 4.72239 2.98 4.10668 3.51 3.55695C4.15 2.86428 4.85 2.52344 5.59 2.52344C5.87 2.52344 6.15 2.58941 6.4 2.72134C6.66 2.85328 6.89 3.05119 7.07 3.33705L9.39 6.93234C9.57 7.20721 9.7 7.46009 9.79 7.70197C9.88 7.93286 9.93 8.16375 9.93 8.37265C9.93 8.63653 9.86 8.9004 9.72 9.15328C9.59 9.40616 9.4 9.67003 9.16 9.93391L8.4 10.8025C8.29 10.9234 8.24 11.0664 8.24 11.2423C8.24 11.3302 8.25 11.4072 8.27 11.4952C8.3 11.5831 8.33 11.6491 8.35 11.7151C8.53 12.0779 8.84 12.5507 9.28 13.1224C9.73 13.6941 10.21 14.2768 10.73 14.8596C11.09 15.2444 11.44 15.6182 11.79 15.948Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.97 20.478C21.97 20.7858 21.92 21.1047 21.82 21.4125C21.79 21.5005 21.76 21.5885 21.72 21.6764C21.55 22.0722 21.33 22.446 21.04 22.7979C20.55 23.3916 20.01 23.8204 19.4 24.0953C19.39 24.0953 19.38 24.1063 19.37 24.1063C18.78 24.3701 18.14 24.5131 17.45 24.5131C16.43 24.5131 15.34 24.2492 14.19 23.7104C13.04 23.1717 11.89 22.446 10.75 21.5335C10.36 21.2146 9.97001 20.8958 9.60001 20.5549L12.87 16.9597C13.15 17.1905 13.4 17.3665 13.61 17.4874C13.66 17.5094 13.72 17.5424 13.79 17.5754C13.87 17.6084 13.95 17.6193 14.04 17.6193C14.21 17.6193 14.34 17.5534 14.45 17.4324L15.21 16.6078C15.46 16.333 15.7 16.1241 15.93 15.9921C16.16 15.8382 16.39 15.7612 16.64 15.7612C16.83 15.7612 17.03 15.8052 17.25 15.9042C17.47 16.0031 17.7 16.146 17.95 16.333L21.26 18.9167C21.52 19.1146 21.7 19.3455 21.81 19.6204C21.91 19.8953 21.97 20.1701 21.97 20.478Z"
                            fill="#0A53B5"
                          />
                        </svg>
                      </Typography>
                      <Typography component={"p"} className="nav_link">
                        +91 9321446611
                      </Typography>
                    </Box>
                    <Box
                      component={"li"}
                      className="nomb"
                      sx={{ display: "flex" }}
                    >
                      <Typography component={"span"}>
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.62 12.1442C17.19 12.1442 16.85 11.7594 16.85 11.2976C16.85 10.8908 16.48 10.0442 15.86 9.30759C15.25 8.59293 14.58 8.17513 14.02 8.17513C13.59 8.17513 13.25 7.79031 13.25 7.32853C13.25 6.86675 13.6 6.48193 14.02 6.48193C15.02 6.48193 16.07 7.07565 16.99 8.14214C17.85 9.14267 18.4 10.3851 18.4 11.2866C18.4 11.7594 18.05 12.1442 17.62 12.1442Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.23 12.1439C20.8 12.1439 20.46 11.759 20.46 11.2973C20.46 7.39412 17.57 4.22763 14.03 4.22763C13.6 4.22763 13.26 3.84281 13.26 3.38103C13.26 2.91925 13.6 2.52344 14.02 2.52344C18.42 2.52344 22 6.45956 22 11.2973C22 11.759 21.65 12.1439 21.23 12.1439Z"
                            fill="#0A53B5"
                          />
                          <path
                            opacity="0.4"
                            d="M11.79 15.948L8.52 19.5433C8.16 19.1915 7.81 18.8287 7.47 18.4548C6.44 17.3114 5.51 16.113 4.68 14.8596C3.86 13.6062 3.2 12.3528 2.72 11.1103C2.24 9.85695 2 8.65852 2 7.51506C2 6.76742 2.12 6.05276 2.36 5.39307C2.6 4.72239 2.98 4.10668 3.51 3.55695C4.15 2.86428 4.85 2.52344 5.59 2.52344C5.87 2.52344 6.15 2.58941 6.4 2.72134C6.66 2.85328 6.89 3.05119 7.07 3.33705L9.39 6.93234C9.57 7.20721 9.7 7.46009 9.79 7.70197C9.88 7.93286 9.93 8.16375 9.93 8.37265C9.93 8.63653 9.86 8.9004 9.72 9.15328C9.59 9.40616 9.4 9.67003 9.16 9.93391L8.4 10.8025C8.29 10.9234 8.24 11.0664 8.24 11.2423C8.24 11.3302 8.25 11.4072 8.27 11.4952C8.3 11.5831 8.33 11.6491 8.35 11.7151C8.53 12.0779 8.84 12.5507 9.28 13.1224C9.73 13.6941 10.21 14.2768 10.73 14.8596C11.09 15.2444 11.44 15.6182 11.79 15.948Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.97 20.478C21.97 20.7858 21.92 21.1047 21.82 21.4125C21.79 21.5005 21.76 21.5885 21.72 21.6764C21.55 22.0722 21.33 22.446 21.04 22.7979C20.55 23.3916 20.01 23.8204 19.4 24.0953C19.39 24.0953 19.38 24.1063 19.37 24.1063C18.78 24.3701 18.14 24.5131 17.45 24.5131C16.43 24.5131 15.34 24.2492 14.19 23.7104C13.04 23.1717 11.89 22.446 10.75 21.5335C10.36 21.2146 9.97001 20.8958 9.60001 20.5549L12.87 16.9597C13.15 17.1905 13.4 17.3665 13.61 17.4874C13.66 17.5094 13.72 17.5424 13.79 17.5754C13.87 17.6084 13.95 17.6193 14.04 17.6193C14.21 17.6193 14.34 17.5534 14.45 17.4324L15.21 16.6078C15.46 16.333 15.7 16.1241 15.93 15.9921C16.16 15.8382 16.39 15.7612 16.64 15.7612C16.83 15.7612 17.03 15.8052 17.25 15.9042C17.47 16.0031 17.7 16.146 17.95 16.333L21.26 18.9167C21.52 19.1146 21.7 19.3455 21.81 19.6204C21.91 19.8953 21.97 20.1701 21.97 20.478Z"
                            fill="#0A53B5"
                          />
                        </svg>
                      </Typography>
                      <Typography component={"p"} className="nav_link">
                        +91 9321446622
                      </Typography>
                    </Box>
                    <Box
                      component={"li"}
                      className="nomb"
                      sx={{ display: "flex" }}
                    >
                      <Typography component={"span"}>
                        {" "}
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.62 12.1442C17.19 12.1442 16.85 11.7594 16.85 11.2976C16.85 10.8908 16.48 10.0442 15.86 9.30759C15.25 8.59293 14.58 8.17513 14.02 8.17513C13.59 8.17513 13.25 7.79031 13.25 7.32853C13.25 6.86675 13.6 6.48193 14.02 6.48193C15.02 6.48193 16.07 7.07565 16.99 8.14214C17.85 9.14267 18.4 10.3851 18.4 11.2866C18.4 11.7594 18.05 12.1442 17.62 12.1442Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.23 12.1439C20.8 12.1439 20.46 11.759 20.46 11.2973C20.46 7.39412 17.57 4.22763 14.03 4.22763C13.6 4.22763 13.26 3.84281 13.26 3.38103C13.26 2.91925 13.6 2.52344 14.02 2.52344C18.42 2.52344 22 6.45956 22 11.2973C22 11.759 21.65 12.1439 21.23 12.1439Z"
                            fill="#0A53B5"
                          />
                          <path
                            opacity="0.4"
                            d="M11.79 15.948L8.52 19.5433C8.16 19.1915 7.81 18.8287 7.47 18.4548C6.44 17.3114 5.51 16.113 4.68 14.8596C3.86 13.6062 3.2 12.3528 2.72 11.1103C2.24 9.85695 2 8.65852 2 7.51506C2 6.76742 2.12 6.05276 2.36 5.39307C2.6 4.72239 2.98 4.10668 3.51 3.55695C4.15 2.86428 4.85 2.52344 5.59 2.52344C5.87 2.52344 6.15 2.58941 6.4 2.72134C6.66 2.85328 6.89 3.05119 7.07 3.33705L9.39 6.93234C9.57 7.20721 9.7 7.46009 9.79 7.70197C9.88 7.93286 9.93 8.16375 9.93 8.37265C9.93 8.63653 9.86 8.9004 9.72 9.15328C9.59 9.40616 9.4 9.67003 9.16 9.93391L8.4 10.8025C8.29 10.9234 8.24 11.0664 8.24 11.2423C8.24 11.3302 8.25 11.4072 8.27 11.4952C8.3 11.5831 8.33 11.6491 8.35 11.7151C8.53 12.0779 8.84 12.5507 9.28 13.1224C9.73 13.6941 10.21 14.2768 10.73 14.8596C11.09 15.2444 11.44 15.6182 11.79 15.948Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M21.97 20.478C21.97 20.7858 21.92 21.1047 21.82 21.4125C21.79 21.5005 21.76 21.5885 21.72 21.6764C21.55 22.0722 21.33 22.446 21.04 22.7979C20.55 23.3916 20.01 23.8204 19.4 24.0953C19.39 24.0953 19.38 24.1063 19.37 24.1063C18.78 24.3701 18.14 24.5131 17.45 24.5131C16.43 24.5131 15.34 24.2492 14.19 23.7104C13.04 23.1717 11.89 22.446 10.75 21.5335C10.36 21.2146 9.97001 20.8958 9.60001 20.5549L12.87 16.9597C13.15 17.1905 13.4 17.3665 13.61 17.4874C13.66 17.5094 13.72 17.5424 13.79 17.5754C13.87 17.6084 13.95 17.6193 14.04 17.6193C14.21 17.6193 14.34 17.5534 14.45 17.4324L15.21 16.6078C15.46 16.333 15.7 16.1241 15.93 15.9921C16.16 15.8382 16.39 15.7612 16.64 15.7612C16.83 15.7612 17.03 15.8052 17.25 15.9042C17.47 16.0031 17.7 16.146 17.95 16.333L21.26 18.9167C21.52 19.1146 21.7 19.3455 21.81 19.6204C21.91 19.8953 21.97 20.1701 21.97 20.478Z"
                            fill="#0A53B5"
                          />
                        </svg>
                      </Typography>
                      <Typography component={"p"} className="nav_link">
                        +91 9321446633{" "}
                      </Typography>
                    </Box>
                    <Box component={"li"} sx={{ display: "flex" }}>
                      <Typography component={"span"}>
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M17 23.0104H7C4 23.0104 2 21.3612 2 17.5131V9.81672C2 5.96855 4 4.31934 7 4.31934H17C20 4.31934 22 5.96855 22 9.81672V17.5131C22 21.3612 20 23.0104 17 23.0104Z"
                            fill="#0A53B5"
                          />
                          <path
                            d="M12 14.6216C11.16 14.6216 10.31 14.3358 9.66003 13.7531L6.53002 11.0044C6.21002 10.7185 6.15003 10.2017 6.41003 9.84991C6.67003 9.49807 7.14003 9.43211 7.46003 9.71797L10.59 12.4667C11.35 13.1373 12.64 13.1373 13.4 12.4667L16.53 9.71797C16.85 9.43211 17.33 9.48708 17.58 9.84991C17.84 10.2017 17.79 10.7295 17.46 11.0044L14.33 13.7531C13.69 14.3358 12.84 14.6216 12 14.6216Z"
                            fill="#0A53B5"
                          />
                        </svg>
                      </Typography>
                      <Typography
                        component={"p"}
                        className="nav_link"
                        sx={{ marginTop: 0.5 }}
                      >
                        contact@tradearth.in
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={3}
              sx={{
                display: "flex",
                justifyContent: { xs: "left", lg: "end" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "left", lg: "right" },
                }}
              >
                <Box className="social_profiles">
                  <Box>
                    <Typography component={"h3"} className="footer_mt">
                      Download Now!
                    </Typography>
                    <Button
                      className="app_links"
                      sx={{ paddingX: "0 !important" }}
                      onClick={() => {
                        window.open(
                          "https://apps.apple.com/in/app/trade-arth/id6470361389"
                        );
                      }}
                    >
                      <img src={appstore} height="100%" width="100%" />
                    </Button>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=dotin.tradearth"
                        )
                      }
                      className="app_links"
                    >
                      <img src={platstore} height="100%" width="100%" />
                    </Button>
                  </Box>

                  <Box component={"ul"} className="social_links">
                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        target="_blank"
                        href={"https://twitter.com/trade_arth"}
                      >
                        <img src={twitter} />
                      </Typography>
                    </Box>

                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        href={"https://t.me/tradearth"}
                        target="_blank"
                      >
                        <img src={tele} />
                      </Typography>
                    </Box>
                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        target="_blank"
                        href={"https://www.facebook.com/TRADEARTH"}
                      >
                        <img src={facebook} />
                      </Typography>
                    </Box>
                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        target="_blank"
                        href={"https://www.youtube.com/@TradeArth"}
                      >
                        <img src={youtube} />
                      </Typography>
                    </Box>
                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        target="_blank"
                        href={"https://wa.aisensy.com/lgxLpy"}
                      >
                        <img src={whatsapp} />
                      </Typography>
                    </Box>
                    <Box component={"li"}>
                      <Typography
                        component={"a"}
                        target="_blank"
                        href={"https://www.instagram.com/trade_arth/"}
                      >
                        <img src={insta} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="footer_copyright">
          <Typography component={"p"}>
            Copyright ©2023 All rights reserved by @ DeMatade Algo Technology
            Solutions Private Limited
          </Typography>
        </Box>
      </Box>
    </>
  );
}
