import React, { useEffect } from "react";
import "./App.scss";
import Mainlayout from "./Mainlayout/Mainlayout";
import Signin from "./Signin/Signin";
import Signup from "./Signin/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {


  return (
    <>
      <ToastContainer position="top-right" />
      <Router>
        <Routes>
          <Route path="/*" element={<Mainlayout />}></Route>
          {/* <Route path="/signin" element={<Signin />}></Route> */}
          {/* <Route path="/signup" element={<Signup />}></Route> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
